<template>

<div class="start sf"> <!-- MAIN CONTAINER -->

  <div v-if="cosaRiproduco==1">
    <img src="img/t10.jpg" :style="{ height: computedHeightImg, 'margin-top': '55px', width: computedWidthImg }" />
  </div>

  <div v-if="cosaRiproduco==4" ref="authors" class="testodoc">
    <p>
       <br><br> <strong>XX secolo - Cultura</strong><br><br>
        Il secolo così complesso e vicino come il Novecento non si piega facilmente a una breve sintesi. In relazione ai terremoti si possono distinguere alcune fasi: (i) fino alla seconda guerra mondiale; (ii) dalla metà del Novecento, che segna per Italia l'inizio della diffusione della rete televisiva, agli inizi dell'affermazione della rete  informatica; (iii) i primi decenni della comunicazione in rete informatica. <br>
Queste tre fasi hanno caratterizzato anche un modo diverso di percepire il disastro sismico e i terremoti in genere, anche se giornali, televisione e rete informatica si sono sovrapposti  e tuttora convivono. <br>
Nei primi decenni del Novecento solo le informazioni sui grandi terremoti erano oggetto di comunicazione nei mass media, mentre i terremoti minori erano conosciuti, oltre che ovviamente dalle persone direttamente coinvolte,  prevalentemente solo dai primi servizi nazionali predisposti per lo studio dei terremoti (si veda anche la sezione "Scienza"). <br>
Le comunicazioni che viaggiavano sui fili telegrafici potevano essere improvvisamente impedite proprio dal fatto che un terremoto poteva causare la caduta dei pali e la lacerazione dei fili che trasmettevano le notizie.   <br>
Solo con la diffusione delle comunicazioni via radio si ovviò in parte a questo problema. Semplificando, si può affermare che fino alla prima metà del Novecento la cultura, intesa come sistema di scambi informativi, recepì principalmente attraverso i giornali l'accadimento dei grandi terremoti, che non erano le uniche calamità che colpivano la popolazione.  <br>
Durante gli anni 1922-1946, del regime fascista e fino alla prima repubblica, la censura di stato ridusse la libertà di stampa: anche le informazioni sui terremoti, essendo  i  disastri sismici un'emergenza che coinvolgeva anche l'ordine pubblico, furono sottoposte a notevoli filtraggi e limitazioni.  Soprattutto, l'opinione pubblica non era mantenuta informata sull'andamento della ricostruzione e sui problemi annessi. <br>
La libera stampa del dopoguerra diede sempre ampio spazio alle informazioni sui terremoti, ma enfatizzando soprattutto aspetti emozionali e retorici. Di fronte a un mondo scientifico sempre più specializzato, i linguaggi della comunicazione si divaricarono velocemente: mentre la stampa rispecchiava il buon senso e i sentimenti ritenuti "comuni"; gli esperti parlavano a una comunità scientifica sempre più specializzata e chiusa in se stessa. <br>
I servizi televisivi aprirono un'era nuova: l'opinione pubblica nazionale fu investita di realtà che succedevano a centinaia di chilometri. In Italia, il primo grande terremoto che entrò nelle case a turbare forse anche qualche  sensibile  coscienza, fu  quello del Belice del gennaio 1968.  Tutti poterono vedere lo sfascio di interi paesi, le case povere e fatiscenti, ridotti a un cumulo di macerie. Tutti poterono vedere una popolazione sopravvissuta che raccoglieva i pochi oggetti rimasti e abbandonava i paesi, spesso per sempre. Fu per l'Italia il primo disastro sismico televisivo e la reazione fu forte. A questo seguì poi il terremoto del Friuli del 1976 e dopo pochi anni quello devastante dell'Irpinia del 1980. <br>
Ma la svolta culturale nuova è avvenuta con il terremoto dell'Umbria e delle Marche del settembre-ottobre 1997: le due scosse del 26 settembre furono trasmesse praticamente in diretta, e l'immagine del crollo di una volta della basilica di Assisi fece il giro del mondo in pochi minuti. Non solo, gruppi di specialisti si misero subito al lavoro per comunicare direttamente in rete gli effetti delle varie scosse, che continuavano a succedersi con inquietante  frequenza. <br>
Il Novecento si  è chiuso con queste nuove e cruciali possibilità di informazione: non solo terremoti in diretta - da tutto il mondo - ma anche accesso a banche dati, cataloghi e dati scientifici prima riservati a pochi esperti o agli operatori del settore. <br><br>
        <br><br><strong>Scienza</strong><br><br>
       Dopo la fase pionieristica della fine del XIX secolo in cui la sismologia si è strutturata come scienza, nel XX secolo si consolidano diversi settori di indagine. Il perfezionamento e la diffusione a livello mondiale di strumenti sismici mette a disposizione una quantità di dati strumentali tale da consentire i primi importanti risultati nella conoscenza della struttura interna della terra.<br>
I grandi terremoti cominciano ad essere studiati con reti sismiche più adeguate e via via sempre più standardizzate. Anche le ricognizioni sul terreno vengono condotte da studiosi e tecnici con diverse specializzazioni: geologi, sismologi e ingegneri.<br>
La rapida crescita della sismologia nel XX secolo, dovuta in parte ad una evoluzione tecnologica senza precedenti, suggerisce di distinguere almeno tre periodi: 1900 - 1920, 1920 - 1960, dopo il 1960.<br>
<br>
Il periodo 1900-1920<br>
Secondo alcuni autori il periodo 1900 - 1920 è considerato la continuazione della fase pionieristica dell'ultimo quarto del XIX secolo.<br>
In realtà, cioé vero solo in parte in quanto in diversi settori della sismologia questo periodo, anche per l'occorrenza di alcuni dei maggiori terremoti del secolo, si sono registrati invece importantissimi risultati e avanzamenti tecnologici e metodologici.<br>
Come è spesso accaduto in passato, l'occorrenza di terremoti disastrosi in diverse parti del mondo: Calabria 1905 e 1907, San Francisco (California, USA) 1906, Messina 1908, Avezzano 1915, ha fornito nuovi importanti dati che hanno contribuito a stimolare nell'ambiente sismologico la ricerca di nuove teorie scientifiche. E' proprio dallo studio del terremoto di San Francisco del 18 aprile 1906 che H.F.Reid nel 1911 elaborò la sua teoria dell'elastic-rebound (rimbalzo elastico): i blocchi crostali che, sotto la lenta azione delle forze tettoniche, in corrispondenza di una faglia si muovono in opposte direzioni (vedi in Glossario) causano un accumulo di energia sotto forma di deformazione elastica delle rocce che si affacciano lungo la faglia. Quando le rocce non sono più in grado di sostenere ulteriore deformazione, lungo la faglia la roccia si rompe con un rimbalzo elastico e la conseguente generazione di onde sismiche.<br>
Ritenuto da Reid come la causa immediata dei terremoti, la teoria dell'elastic-rebound troverà successive conferme e si inserisce nei modelli interpretativi correnti di rilascio dell'energia accumulata dalle rocce crostali.<br>
Sempre nei primi anni del '900 Emil Wiechert a Gšttingen (Germania), ispirandosi all'esperienza dei sismologi italiani, e il Principe Boris Galitzin  a Pulkovo (Russia) mettono a punto due strumenti che per la sensibilità e la qualità delle registrazioni si diffonderanno in tutto il mondo e in molti casi funzioneranno fino alla fine degli anni'70.<br>
La rete sismica installata su scala mondiale da John Milne negli ultimi anni del XIX secolo e che nel 1910 si compone di oltre 50 stazioni equipaggiate con strumenti ideati dallo stesso Milne, costitusce il primo passo verso una visione globale della sismicità. <br>
<br>
Periodo 1920 - 1960: il periodo classico<br>
Agli inizi degli anni '20 nascono numerose importanti istituzioni internazionali dedicate alla raccolta e sistematizzazione dei dati relativi a epicentri di terremoti avvenuti in tutte le parti della Terra. Fra questi spiccano per importanza l'International Seismological Summary (1922) e la Jesuit Seismological Association fondata negli Stati uniti nel 1925 con stazioni sismiche in tutto il mondo.<br>
Nonostante i tentativi di omogeneizzazione della strumentazione sismica operata da queste istituzioni, la maggior parte degli strumenti nel mondo è ancora eterogenea. Il sempre più diffuso utilizzo di segnali orari trasmessi via radio consente dagli inizi di questo periodo una migliore taratura delle misure orarie utilizzate nelle registrazioni sismiche. Quest'ultimo aspetto favorisce il perfezionamento dei modelli di propagazione delle onde sismiche all'interno della terra.<br>
Complici alcune emigrazioni di importanti studiosi dall'Europa agli Stati Uniti (Beno Gutenberg, Hugo Benioff ecc.), agli inizi degli anni '30 nasce a Berkeley in California uno dei più importanti centri di ricerca sismologica del mondo. Qui, per quanto riguarda la sismologia strumentale, Harry Wood, John Anderson e Hugo Benioff danno vita ad una nuova generazione di strumenti sismici destinati a funzionare fino agli anni '80 -'90. La diffusione di questa strumentazione e il suo carattere di omogeneità favorisce la definizione da parte di Charles Richter della prima scala di magnitudo a cui comunemente ci si riferisce come scala Richter. Viene così definita la prima grandezza in grado di rappresentare l'energia liberata dalla sorgente sismica. <br>
Gli sviluppi di una comunità sismologica in Giappone, conseguenti al grande impulso dato da John Milne nella sua "stagione giapponese" dell'ultimo quarto dell'Ottocento, fornisce in questo periodo un contributo importante alla migliore conoscenza dei meccanismi di generazione dei terremoti.<br>
In Italia si va meglio strutturando la raccolta dei dati strumentali e di quelli macrosismici, cioé relativi agli effetti dei terremoti sulle case, le persone e le cose.  Sono anni in cui il modello istituzionale italiano della ricerca in questo settore, di stampo ottocentesco, non regge il confronto con le grandi istituzioni internazionali. Nel 1936 le competenze geofisiche dell'Ufficio Centrale di Meteorologia e Geofisica vengono trasferite all'Istituto Nazionale di Geofisica (ING), appena costituito presso il Consiglio Nazionale delle Ricerche. Nei decenni successivi l'ING ammodernerà, integrerà e renderà più omogenea e sensibile la rete sismometrica italiana con strumenti Wiechert orizzontali e verticali e Galitzin costruiti dalle officine dell'Istituto.  Di pari passo con l'osservazione strumentale l'ING manterrà la raccolta di dati macrosismici sia attraverso questionari macrosismici sia attraverso l'osservazione diretta in caso di effetti distruttivi. Questo aspetto osservazionale descrittivo e classificatorio della sismologia si è andato sviluppando e affermando di pari passo con quello strumentale tanto che i due approcci hanno costituito per alcuni decenni due linee di ricerca parallele e quasi alternative: la macrosismica e la microsismica.<br>
<br>
Dopo il 1960: l'era moderna<br>
Forse mai come in questo periodo della storia, l'evoluzione della tecnologia, soprattutto nell'elettronica, ha influito nello sviluppo della scienza e in particolare nella sismologia.<br>
Negli anni '60 si assiste ad alcune fondamentali svolte in questa scienza che dipendono soprattutto da due fattori: la realizzazione di una rete mondiale di strumenti standard con lo scopo di registrare le esplosioni nucleari sotterranee nel mondo, la World-Wide Standardized Seismic Network (WWSSN) e l'introduzione del calcolo elettronico nell'applicazione di nuovi modelli interpretativi della generazione e della propagazione delle onde sismiche. <br>
La grande quantità di dati resi disponibili con la rete globale WWSSN e le successive evoluzioni, consentono di delineare una nuova geografia della sismicità mondiale. Si passa da una sismologia di tipo quasi esclusivamente descrittivo ad una scienza in grado di spiegare una parte dei fenomeni osservati. Gli strumenti di calcolo, via via straordinariamente potenziati nei decenni, il confronto con discipline differenti, come la statistica, la geologia, l'ingegneria, la chimica e la storia, favoriscono la specializzazione della sismologia in numerosi campi di indagine: quello della sismogenesi, dell'ingegneria sismica, della pericolosità e del rischio sismico, quello della previsione dei terremoti.<br>
E' il tempo di una relativa maturità disciplinare, della ricerca di un equilibrio fra conoscere il terremoto per capire la natura e conoscerlo per difendersi dai suoi effetti.<br>
    </p>
  </div>
  
  <transition name="slide-fade" >
    <span ref="a1980" v-if="showLink" @click="startVideo(2); testoCheScorre='Un DC9 esplode nei cieli di Ustica il 27 giugno uccidendo 81 persone &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Stazione di Bologna, 2 agosto esplode una bomba, 85 i morti &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Scoppia la guerra tra l\'Iran e l\'Iraq che durerà fino al 1988 causando 1.500.000 di morti &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Umberto Eco pubblica il romanzo \'Il nome della rosa\' '" class="terremotiArrow" style="mposition:absolute; top:70px; z-index:20; cursor:pointer; position: absolute; ">
      <div>1980</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="showDeepening" @click="startVideo(3)" class="terremotiArrow" style="mposition:absolute; top:120px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Approfondimento</div>
    </span>
  </transition>

    <video-player :class="videoPlayerBox" 
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
 
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 >
  </video-player>

   <div id="nav" class="marquee" >
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhereGlobe(); testoCheScorre=''" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario'); testoCheScorre=''" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori'); testoCheScorre=''" src="img/autori.png" class="rightmastericons" />
        </div>

        <div class="documentiicon">
            <img @click="cosaRiproduco=4; goToDocument(); testoCheScorre=''" src="img/documenti-icon.png" class="rightmastericons" />
        </div>

    </div>

  <div id="footer" class="marquee" >
      <p v-html="testoCheScorre">
        
        
      </p>
    </div>

</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Dal 1918 al 1990 ',
        testoCheScorre : '',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
        computedHeightImg : '200px',
        computedWidthImg : '200px',
        audioFile : 'audio/10.mp3',
        videoPlayerBox : 'video-player-box notvisible',
        audioCanPlay : false,
        audioObject : null,
        cosaRiproduco : 1,
        cosaRiproducoPosizione : 0,
        showLink : false,
        showDeepening : false,
        reStart : false,
        playerOptions: {
          // videojs options
          muted: false,
          controls: false,
          autoplay: false,
          language: 'it',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: "video/1980/1980.mp4"
          }],
          //poster: "/public/img/mstile-150x150.png",
        }
        
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)

      this.computedHeight = this.windowHeight+'px';
      this.computedHeightImg = (this.windowHeight-60)+'px';
      this.computedWidthImg = this.windowWidth+'px';

      if(this.windowHeight>this.windowWidth){
        this.computedHeightImg = 'auto';
      }else{
        this.computedWidthImg = 'auto';
      }
      console.log(this.windowHeight);

      this.player.width(this.windowWidth);
      this.player.height(this.windowHeight-110); 
      
    },
    computed: {
     player() {
        return this.$refs.videoPlayer.player
      },
      
    },
    methods: {
      startVideo(what){
          this.cosaRiproduco = what;
          this.videoPlayerBox = 'video-player-box yesvisible';
          console.log(this.videoPlayerBox);
          // il primo video
          if(what==2){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = 0;
              this.player.src("video/1980/1980.mp4");
              this.player.currentTime(120);
              this.player.play();
              console.log(this.player.currentTime());
          }
          if(what==3){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1980/1980_T01/1980_T01.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }
          this.changeSlidingText();

      },
      goToDocument(){
        this.stopSound(this.audioFile);
        this.videoPlayerBox = 'video-player-box notvisible';
        this.cosaRiproducoPosizione = this.player.currentTime();
        this.player.pause();
      },
      goWhereGlobe(){
          // resetto e torno indietro #########################################
          console.log(this.cosaRiproduco+'<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
            if(this.cosaRiproduco==1){
                router.push('/home');
            }

            if(this.cosaRiproduco==2){
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
                //this.cosaRiproducoPosizione = 0;
            }

            if(this.cosaRiproduco==3){
                this.cosaRiproduco=2;
                this.player.pause();
                this.player.src("video/1980/1980.mp4");
                //this.player.currentTime(this.cosaRiproducoPosizione);
                this.player.play();
                
            }

            if(this.cosaRiproduco==4){ 
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
            }

            console.log(this.cosaRiproduco);
            this.changeSlidingText();
       },
      goWhere(destination){
            //let whereReturn = localStorage.getItem('whereicomefrom');
            localStorage.setItem('whereicomefrom', 't10');
            if(destination=='back'){
                router.push('/home');
            }else{
                router.push('/'+destination);
            }
             
       },
       // listen event
      onPlayerPlay(player) {
         console.log('player play!'+ player + 'ppp' + parseInt(this.cosaRiproducoPosizione));
         if(this.cosaRiproduco==2){player.currentTime(parseInt(this.cosaRiproducoPosizione));}else{ player.currentTime(0); }
         this.videoStatus = 'PLAY';
      },
      onPlayerPause(player) {
         console.log('player pause!', player);
         this.videoStatus = 'PAUSE';
      },
      onPlayerEnded(player) {
         console.log('player ended!', player);
         this.videoStatus = 'ENDED';
         this.goWhereGlobe();
      },
      onPlayerLoadeddata(player) {
         console.log('player Loadeddata!', player);
      },
      onPlayerWaiting(player) {
         console.log('player Waiting!', player);
      },
      onPlayerPlaying(player) {
         console.log('player Playing!', player);
         
      },
      onPlayerTimeupdate(player) {
          //if(player.currentTime()<30){player.currentTime(30)}
          //if(this.cosaRiproduco==2 && this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(30);  console.log('dio');  }

         console.log('player Timeupdate!'+ player.currentTime() + '---' + parseInt(this.cosaRiproducoPosizione) + '|||' + this.reStart);
         if(this.cosaRiproduco==2){
             //if(this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(10); this.reStart = false;  }
             
             //this.reStart=false;
             if(player.currentTime()>10){
                 this.showDeepening = true;
             }else{
                 this.showDeepening = false;
             }
             
         }else{
             this.showDeepening = false;
         }
         
         
      },
      onPlayerCanplay(player) {
         console.log('player Canplay!', player);
         
      },
      onPlayerCanplaythrough(player) {
         console.log('player Canplaythrough!', player);

      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState);
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player);
        
        //player.currentTime(0);
        // console.log('example 01: the player is readied', player)
      },
       myEventHandler(e) {
             // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            this.player.height(this.windowHeight-110); 
            //this.player.height(e.target.innerHeight-40);


            this.windowWidth = e.target.innerWidth;
            this.computedHeight = e.target.innerHeight+'px';
            this.computedHeightImg = (e.target.innerHeight-60)+'px';
            this.computedWidthImg = this.windowWidth+'px';

            if(e.target.innerHeight>e.target.innerWidth){
              this.computedHeightImg = 'auto';
            }else{
              this.computedWidthImg = 'auto';
            }
            
      },
      showLinkFunction(){
          this.showLink = true;
      },
      changeSlidingText(){

        if(this.cosaRiproduco==2){
          this.$refs.a1980.className  = 'terremotiArrowG';
        }

        if(this.cosaRiproduco==1){
          this.$refs.a1980.className  = 'terremotiArrow';
        }
        
        setTimeout(() => this.changeSlidingText(),20000);
        
      },
      playSound (sound) {
        if(sound) {
            this.audioObject = new Audio(sound);
            this.audioObject.play();
        }
      },
      stopSound (sound) {
        if(sound) {
            
            this.audioObject.pause();
            //this.audioObject.currentTime = 0;
        }
      },
      recallSound(sound){
          if(this.audioCanPlay==true){
            if(this.cosaRiproduco==1){
                this.playSound(sound);
            }
            
            setTimeout(() => this.recallSound(sound), 160000);
            console.log('qui?????');
          }
          
      },

    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        this.audioCanPlay = true;
        setTimeout(() => this.recallSound(this.audioFile), 500);
        setTimeout(() => this.showLinkFunction(), 1000);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
        this.audioCanPlay = false;
        this.stopSound(this.audioFile);
    },
  }
</script>
<style scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active { 
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .terremotiArrow{
    left: -10px;
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrow div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .terremotiArrowG{
    left: -10px;
    background-color: green;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrowG div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .notvisible{
    visibility: hidden;
    
    overflow: hidden;
    width: 0;
    height: 0;
      
  }
  .yesvisible{
    visibility: visible;
    margin-top: 60px;
    overflow: hidden;
    width: unset;
    height: unset;
      
  }
  
  p{
      font-size: 1.4em;
  }
</style>