<template>

<div class="start sf"> <!-- MAIN CONTAINER -->

  <div v-if="cosaRiproduco==1">
    <img src="img/t04.jpg" :style="{ height: computedHeightImg, 'margin-top': '55px', width: computedWidthImg }" />
  </div>

  <div v-if="cosaRiproduco==4" ref="authors" class="testodoc">
    <p>
        <strong>Il XIII e XIV secolo - Cultura</strong><br><br>
        In questi secoli domina ancora una sviluppata attenzione agli eventi come segni, in un'attitudine interpretativa che riporta i vari aspetti del mondo a un loro significato religioso. Nella cultura religiosa, quella che ha lasciato il maggior numero di testimonianze, il terremoto è recepito come un frammento di Apocalisse, un evento da interpretare in una visione spirituale e finalistica. Nella cultura più laica e urbana, invece, gli effetti sismici sono vissuti come una calamità propria dei centri urbani, sempre più densamente abitati.<br>
Il carattere distruttivo del terremoto è legato al profondo mutamento del contesto abitativo che caratterizza questo periodo storico. La popolazione urbana aumenta, e aumenta nelle città anche il valore degli immobili; aumentano quindi anche i costi economici dei danni. Le abitazioni delle città medievali sono caratterizzate da un forte verticalismo, fra l'altro ben rappresentato nella pittura contemporanea: in molti quadri dell'epoca, dai paesaggi agresti o collinari spiccano torri, case addossate le une alle altre, strette entro le mura. I nuovi spazi urbani sono infatti il risultato di innalzamenti e sopraelevazioni o di nuove costruzioni, dove prima c'erano orti o spazi abbandonati: questi mutamenti influenzano la natura degli effetti sismici, aumentando in genere la distruttività dei terremoti.<br>
Nel Trecento, la vita cittadina ha ormai assunto le caratteristiche di una cultura diffusa, basata su scambi commerciali e mercantili e su attività artigianali, con due conseguenze principali. Dal punto di vista politico, si diversificano le strutture amministrative, relative ai poteri sia pubblici sia ecclesiastici; in campo culturale, e più specificamente memorialistico, si affermano e si radicano le robuste tradizioni locali delle storie cittadine, una cronachistica autorevole che diviene poi la base della successiva storiografia urbana.<br>
Molto solide e capillarmente presenti sul territorio sono in questi secoli le organizzazioni ecclesiastiche e monastiche, che continuano la loro tradizionale opera di conservazione e trasmissione della cultura.<br>
        <br><br><strong>Scienza</strong><br><br>
       Gli ultimi secoli hanno visto una grande diffusione in area occidentale delle acquisizioni teoriche della filosofia naturale araba, relativamente ai commenti dell'opera di Aristotele.  La cultura araba, a cui le scienze sono debitrici, non ha "inventato" nuove teorie interpretative del terremoto, quanto piuttosto aggiunto nuove osservazioni empiriche per convalidarle. Si tratta d'altra parte del modello di progresso teorico dell'epoca: le Ôinvenzioni' Ð che in genere costituiscono dei perfezionamenti di dottrine già note Ð non sono mai delle 'rivoluzioni'. <br>
Nel complesso, il dibattito scientifico in questi secoli è fortemente orientato al piano filosofico, caratteristica non sorprendente in un'epoca che tende all'interpretazione religiosa e simbolica del mondo e dei suoi fenomeni. Le diatribe filosofiche hanno per˜ una diretta influenza sull'elaborazione dei modelli interpretativi del terremoto e sul modello terrestre in genere.<br>
La cultura dell'Occidente latino è molto influenzata dall'opera di Averroè (Muhammad ibn Ahmad Muhammad ibn Rushd, 1126-1198) il quale postula l'eternità della materia: la scienza, e non l'ascesi mistica, costituisce per questo filosofo naturale la modalità del ricongiungimento a Dio. Averroè è condannato nel 1195 ed esiliato per le sue dottrine; nel XIII secolo gli averroisti capovolgono alcune sue posizioni, giungendo a sostenere l'esistenza della "separazione" fra verità di fede e verità di ragione. In questo modo si scardina il primato del pensiero religioso su quello naturalistico, posizione che vedrà nettamente contrari Bonaventura e Tommaso d'Aquino, timorosi dell'affermarsi di una sorta di "doppia verità".<br>
Alberto Magno (1206-1280),  il cui pensiero conosce una notevole diffusione soprattutto nel XIV e XV secolo, con il suo trattato De mineralibus et rebus metallicis (circa 1260) costituisce un punto di riferimento per l'intero pensiero "sismologico" occidentale fino alle soglie del XVI secolo. Alberto Magno asserisce che le scienze naturali (definite "profane") devono essere coltivate con metodi distinti da quelli propri della teologia, e sostiene la validità di una ricerca ispirata solo alla ragione.<br>
Ma i molti problemi suscitati dal contrasto fra interpretazione religiosa e modello teorico hanno una nuova e solida sistemazione filosofica solo con Tommaso d'Aquino (1221-1274). <br>
Riguardo all'interpretazione del terremoto, che è parte della sua cosmogonia basata sul geocentrismo aristotelico, Tommaso pone una gerarchia di valori che non risolve tuttavia il conflitto fra ideologia religiosa e modello razionalista nell'interpretazione dei fenomeni naturali dei quali pone  Dio come causa prima e la materia come causa seconda.<br>
    </p>
  </div>
  
  <transition name="slide-fade" >
    <span ref="a1348" v-if="showLink" @click="startVideo(2); testoCheScorre='Ormai milioni le vittime dell\'epidemia di peste nera che sta devastando l\'Europa &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il re di Germania Carlo IV fonda a Praga la prima università tedesca. E\' anche la più antica dell\'Europa centrale &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Il Regno di Napoli è invaso dall\'esercito ungherese guidato dal re Luigi I d\'Angiò, che vuole vendicare la morte del fratello Andrea'" class="terremotiArrow" style="mposition:absolute; top:70px; z-index:20; cursor:pointer; position: absolute; ">
      <div>1348</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <!-- <p></p> -->
  </transition>

    <video-player :class="videoPlayerBox"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
 
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 >
  </video-player>

   <div id="nav" class="marquee" >
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhereGlobe(); testoCheScorre=''" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario'); testoCheScorre=''" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori'); testoCheScorre=''" src="img/autori.png" class="rightmastericons" />
        </div>

        <div class="documentiicon">
            <img @click="cosaRiproduco=4; goToDocument(); testoCheScorre=''" src="img/documenti-icon.png" class="rightmastericons" />
        </div>

    </div>

  <div id="footer" class="marquee" >
      <p v-html="testoCheScorre">
        
        
      </p>
    </div>

</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Dal XIV secolo alla seconda metà del XV  ',
        testoCheScorre : '',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
        computedHeightImg : '200px',
        computedWidthImg : '200px',
        audioFile : 'audio/04.mp3',
        videoPlayerBox : 'video-player-box notvisible',
        audioCanPlay : false,
        audioObject : null,
        cosaRiproduco : 1,
        cosaRiproducoPosizione : 0,
        showLink : false,
        showDeepening : false,
        reStart : false,
        playerOptions: {
          // videojs options
          muted: false,
          controls: false,
          autoplay: false,
          language: 'it',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: "video/1348/1348.mp4"
          }],
          //poster: "/public/img/mstile-150x150.png",
        }
        
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)

      this.computedHeight = this.windowHeight+'px';
      this.computedHeightImg = (this.windowHeight-60)+'px';
      this.computedWidthImg = this.windowWidth+'px';

      if(this.windowHeight>this.windowWidth){
        this.computedHeightImg = 'auto';
      }else{
        this.computedWidthImg = 'auto';
      }
      console.log(this.windowHeight);

      this.player.width(this.windowWidth);
      this.player.height(this.windowHeight-110); 
      
    },
    computed: {
     player() {
        return this.$refs.videoPlayer.player
      },
      
    },
    methods: {
      startVideo(what){
          this.cosaRiproduco = what;
          this.videoPlayerBox = 'video-player-box yesvisible';
          console.log(this.videoPlayerBox);
          // il primo video
          if(what==2){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = 0;
              this.player.src("video/1348/1348.mp4");
              this.player.currentTime(120);
              this.player.play();
              console.log(this.player.currentTime());
          }
          if(what==3){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1348/1348.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }

          this.changeSlidingText();

      },
      goToDocument(){
        this.stopSound(this.audioFile);
        this.videoPlayerBox = 'video-player-box notvisible';
        this.cosaRiproducoPosizione = this.player.currentTime();
        this.player.pause();
      },
      goWhereGlobe(){
          // resetto e torno indietro #########################################
          console.log(this.cosaRiproduco+'<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
            if(this.cosaRiproduco==1){
                router.push('/home');
            }

            if(this.cosaRiproduco==2){
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
                //this.cosaRiproducoPosizione = 0;
            }

            if(this.cosaRiproduco==3){
                this.cosaRiproduco=2;
                this.player.pause();
                this.player.src("video/1348/1348.mp4");
                //this.player.currentTime(this.cosaRiproducoPosizione);
                this.player.play();
                
            }

            if(this.cosaRiproduco==4){ 
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
            }

            console.log(this.cosaRiproduco);
            this.changeSlidingText();
       },
      goWhere(destination){
            //let whereReturn = localStorage.getItem('whereicomefrom');
            localStorage.setItem('whereicomefrom', 't04');
            if(destination=='back'){
                router.push('/home');
            }else{
                router.push('/'+destination);
            }
             
       },
       // listen event
      onPlayerPlay(player) {
         console.log('player play!'+ player + 'ppp' + parseInt(this.cosaRiproducoPosizione));
         if(this.cosaRiproduco==2){player.currentTime(parseInt(this.cosaRiproducoPosizione));}else{ player.currentTime(0); }
         this.videoStatus = 'PLAY';
      },
      onPlayerPause(player) {
         console.log('player pause!', player);
         this.videoStatus = 'PAUSE';
      },
      onPlayerEnded(player) {
         console.log('player ended!', player);
         this.videoStatus = 'ENDED';
         this.goWhereGlobe();
      },
      onPlayerLoadeddata(player) {
         console.log('player Loadeddata!', player);
      },
      onPlayerWaiting(player) {
         console.log('player Waiting!', player);
      },
      onPlayerPlaying(player) {
         console.log('player Playing!', player);
         
      },
      onPlayerTimeupdate(player) {
          //if(player.currentTime()<30){player.currentTime(30)}
          //if(this.cosaRiproduco==2 && this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(30);  console.log('dio');  }

         console.log('player Timeupdate!'+ player.currentTime() + '---' + parseInt(this.cosaRiproducoPosizione) + '|||' + this.reStart);
         if(this.cosaRiproduco==2){
             //if(this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(10); this.reStart = false;  }
             
             //this.reStart=false;
             if(player.currentTime()>10){
                 this.showDeepening = true;
             }else{
                 this.showDeepening = false;
             }
             
         }else{
             this.showDeepening = false;
         }
         
         
      },
      onPlayerCanplay(player) {
         console.log('player Canplay!', player);
         
      },
      onPlayerCanplaythrough(player) {
         console.log('player Canplaythrough!', player);

      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState);
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player);
        
        //player.currentTime(0);
        // console.log('example 01: the player is readied', player)
      },
       myEventHandler(e) {
             // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            this.player.height(this.windowHeight-110); 
            //this.player.height(e.target.innerHeight-40);


            this.windowWidth = e.target.innerWidth;
            this.computedHeight = e.target.innerHeight+'px';
            this.computedHeightImg = (e.target.innerHeight-60)+'px';
            this.computedWidthImg = this.windowWidth+'px';

            if(e.target.innerHeight>e.target.innerWidth){
              this.computedHeightImg = 'auto';
            }else{
              this.computedWidthImg = 'auto';
            }
            
      },
      showLinkFunction(){
          this.showLink = true;
      },
      changeSlidingText(){

        if(this.cosaRiproduco==2){
          this.$refs.a1348.className  = 'terremotiArrowG';
        }

        if(this.cosaRiproduco==1){
          this.$refs.a1348.className  = 'terremotiArrow';
        }
        
        setTimeout(() => this.changeSlidingText(),20000);
        
      },
      playSound (sound) {
        if(sound) {
            this.audioObject = new Audio(sound);
            this.audioObject.play();
        }
      },
      stopSound (sound) {
        if(sound) {
            
            this.audioObject.pause();
            //this.audioObject.currentTime = 0;
        }
      },
      recallSound(sound){
          if(this.audioCanPlay==true){
            if(this.cosaRiproduco==1){
                this.playSound(sound);
            }
            
            setTimeout(() => this.recallSound(sound), 160000);
            console.log('qui?????');
          }
          
      },

    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        this.audioCanPlay = true;
        setTimeout(() => this.recallSound(this.audioFile), 500);
        setTimeout(() => this.showLinkFunction(), 1000);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
        this.audioCanPlay = false;
        this.stopSound(this.audioFile);
    },
  }
</script>
<style scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active { 
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .terremotiArrow{
    left: -10px;
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrow div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .terremotiArrowG{
    left: -10px;
    background-color: green;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrowG div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .notvisible{
    visibility: hidden;
    
    overflow: hidden;
    width: 0;
    height: 0;
      
  }
  .yesvisible{
    visibility: visible;
    margin-top: 60px;
    overflow: hidden;
    width: unset;
    height: unset;
      
  }

  p{
      font-size: 1.4em;
  }
</style>