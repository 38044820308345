<template>

<div class="start sf" id="mainP" :style="{ height: computedHeight }"> <!-- MAIN CONTAINER -->

   <div id="nav" class="marquee">
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhere('back')" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario')" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori')" src="img/autori.png" class="rightmastericons" />
        </div>

    </div>

    <div ref="glossary" class="glossariocontainer">
        <div>
            <button @click="changeCurrentLetter('A')" class="glosButtonA">A</button>
            <button @click="changeCurrentLetter('B')" class="glosButtonB glosButtonVoid">B</button>
            <button @click="changeCurrentLetter('C')" class="glosButtonA">C</button>
            <button @click="changeCurrentLetter('D')" class="glosButtonB">D</button>
            <button @click="changeCurrentLetter('E')" class="glosButtonA">E</button>
            <button @click="changeCurrentLetter('F')" class="glosButtonB">F</button>
            <button @click="changeCurrentLetter('G')" class="glosButtonA">G</button>
            <button @click="changeCurrentLetter('H')" class="glosButtonB glosButtonVoid">H</button>
            <button @click="changeCurrentLetter('I')" class="glosButtonA">I</button>
            <button @click="changeCurrentLetter('J')" class="glosButtonB glosButtonVoid">J</button>
            <button @click="changeCurrentLetter('K')" class="glosButtonA glosButtonVoid">K</button>
            <button @click="changeCurrentLetter('L')" class="glosButtonB">L</button>
            <button @click="changeCurrentLetter('M')" class="glosButtonA">M</button>
            <button @click="changeCurrentLetter('N')" class="glosButtonB">N</button>
            <button @click="changeCurrentLetter('O')" class="glosButtonA">O</button>
            <button @click="changeCurrentLetter('P')" class="glosButtonB">P</button>
            <button @click="changeCurrentLetter('Q')" class="glosButtonA glosButtonVoid">Q</button>
            <button @click="changeCurrentLetter('R')" class="glosButtonB">R</button>
            <button @click="changeCurrentLetter('S')" class="glosButtonA">S</button>
            <button @click="changeCurrentLetter('T')" class="glosButtonB">T</button>
            <button @click="changeCurrentLetter('U')" class="glosButtonA glosButtonVoid">U</button>
            <button @click="changeCurrentLetter('V')" class="glosButtonB">V</button>
            <button @click="changeCurrentLetter('W')" class="glosButtonA glosButtonVoid">W</button>
            <button @click="changeCurrentLetter('X')" class="glosButtonB glosButtonVoid">X</button>
            <button @click="changeCurrentLetter('Y')" class="glosButtonA glosButtonVoid">Y</button>
            <button @click="changeCurrentLetter('Z')" class="glosButtonB">Z</button>
        </div>
        <div class="glossariopage">
            <div v-if="this.currentLetter=='A'">
                <ul>
                    <li>
                        <h2>Area epicentrale</h2>
                        <p>
                            Area dei maggiori effetti di un terremoto.
                        </p>
                    </li>
                    <li>
                        <h2>Architettura vernacolare</h2>
                        <p>
                            Con questi termini si intende l’edilizia abitativa popolare, ossia quella costruita nel tempo dai saperi costruttivi locali. E’ l’edilizia “povera”, creata fuori dai trattati di architettura e dalle grandi scuole, ma che ha caratterizzato il paesaggio culturale italiano. I suoi linguaggi costruttivi, l’uso dei materiali locali, le molteplici soluzioni funzionali a società di residenti, per lo più con scarse risorse, sono oggetto di analisi e di studi anche in relazione ad alcune qualità volutamente sismo-resistenti di tale edificato storico.
                        </p>
                    </li>
                </ul>
            </div>
            <div v-if="this.currentLetter=='B'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='C'">
                <ul>
                    <li>
                        <h2>Cataloghi sismici</h2>
                        <p>
                            Sono degli elaborati, ovviamente assai diversi per periodi storici e finalità degli autori, costituiti da serie storiche di terremoti, ordinate cronologicamente. Le orgini culturali dei cataloghi sono antichissime e affondano nella tradizione naturalistica greca. Per l’Italia il catalogo dei terremoti più antico che si conosca è stato scritto nel 1456 dall’umanista fiorentino Giannozzo Manetti. L’Italia ha poi avuto una tradizione eccezionale in questo settore di studi, tanto che oggi il catalogo storico italiano è il più importante del mondo per qualità dell’informazione  e ampiezza dell’arco cronologico. I dati contenuti nei cataloghi dei terremoti, debitamente parametrizzati, costituiscono la base per le stime della pericolosità e della scuotibilità.
                        </p>
                    </li>
                    <li>
                        <h2>Classificazione sismica</h2>
                        <p>
                            Sulla base della frequenza ed intensità dei terremoti del passato, il territorio italiano è stato classificato in tre categorie sismiche che prevedono l’applicazione di livelli crescenti di protezione per le costruzioni (massima per la 1a categoria). Complessivamente è classificato sismico il 45% del territorio nazionale, nel quale vive il 40% della popolazione. La classificazione sismica vigente è stata adottata tra il 1981 ed il 1984; attualmente il Ministero dei Lavori Pubblici ha all’esame una proposta di riclassificazione basata su valutazioni di rischio, che prevede di estendere al 67% la percentuale di territorio classificato sismico.
                        </p>
                    </li>
                </ul>
            </div>
            <div v-if="this.currentLetter=='D'">
                <ul>
                    <li>
                        <h2>Disastro sismico</h2>
                        <p>
                            Secondo la teoria dei disastri di origine naturale, si intende un fenomeno sismico che supera la capacità del sistema antropico di assorbire gli effetti distruttivi. Si ha quindi un disastro quando con il terremoto collassano non solo le abitazioni, ma anche le strutture strategiche di una società (ospedali, scuole, reti di comunicazione, reti produttive e commerciali ecc.).  Questi risultati sono in relazione diretta non solo con l’energia del terremoto, ma anche con la qualità e lo stato di conservazione del patrimonio edilizio e con l’efficienza delle reti di funzionamento di un sistema abitativo.  
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='E'">
                <ul>
                    <li>
                        <h2>Edilizia civile minore</h2>
                        <p>
                            Vedi "architettura vernacolare".
                        </p>
                    </li>
                    <li>
                        <h2>Edilizia sismo-resistente</h2>
                        <p>
                            E’ l’edilizia volutamente progettata e realizzata per resistere ai forti terremoti. Non è solo un’invenzione dell’ingegneria moderna, ma una sorta di sfida storica, su cui si sono misurati anche antichi saperi costruttivi. Al mondo sono note molte soluzioni architettoniche progettate, nella forma e nei materiali, per mitigare gli effetti dei terremoti: dai templi giapponesi e indiani, alle chiese armene, alle piccole case di bambù dell’America centro meridionale.  Anche in Italia sono stati rilevati questi tentativi, soprattutto nelle aree dove i terremoti sono più frequenti, come nell’Italia centrale. Si tratta di misure preventive che mirano a consolidare l’edificio e a renderlo atto a sostenere le spinte orizzontali.
                        </p>
                    </li>
                    <li>
                        <h2>Effetti di sito</h2>
                        <p>
                            Quando le onde sismiche arrivano in superficie provocano il movimento del suolo. L’ampiezza, la velocità e l’accelerazione (verticale e, soprattutto, orizzontale) di questo movimento possono subire amplificazioni o attenuazioni in funzione del terremoto e della natura e della geometria dei terreni che le onde sismiche attraversano in prossimità della superficie. L’amplificazione del moto del suolo avviene anche in corrispondenza di particolari forme del rilievo, ad esempio alla sommità di dorsali montuose particolarmente aguzze, dove i fronti d’onda, deformandosi, si concentrano.
                        </p>
                    </li>
                    <li>
                        <h2>Epicentro</h2>
                        <p>
                            Punto della superficie terrestre in cui è convenzionalmente localizzato il terremoto. Si distinguono due tipi di epicentro, quello strumentale e quello macrosismico. Il primo è la proiezione dell’ipocentro sulla superficie terrestre; il secondo è il baricentro dell’area dei maggiori effetti (area epicentrale). La localizzazione dei due epicentri non può essere coincidente, in quanto quello strumentale è correlato alla sorgente del terremoto, mentre quello macrosismico dipende dall'interazione fra le onde sismiche e il territorio abitato.
                        </p>
                    </li>
                    <li>
                        <h2>Esposizione</h2>
                        <p>
                            Rappresenta ciò che è esposto ­ popolazione, beni, attività, ecc. ­ al rischio di subire un danno a causa di un terremoto. Un centro abitato densamente popolato, ad esempio, è maggiormente esposto al danno di un piccolo centro, privo di beni monumentali ed architettonici o di attività economiche di rilievo. Con la pericolosità e la vulnerabilità definisce il rischio sismico di un territorio.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='F'">
                <ul>
                    <li>
                        <h2>Faglia</h2>
                        <p>
                            Una faglia è una frattura o un sistema di fratture lungo le quali la roccia risulta dislocata in due blocchi che scorrono l’uno rispetto all’altro; essa può variare, in lunghezza, da pochi centimetri fino a migliaia di chilometri. Sulla base del tipo di movimento vengono distinti tre tipi di faglie principali: normali, inverse e trascorrenti. Durante un terremoto si ha l’improvvisa rottura della roccia lungo una faglia con il rilascio di energia elastica. Una faglia si considera attiva quando si è mossa negli ultimi 10.000 anni.
                        </p>
                    </li>
                    <li>
                        <h2>Frana</h2>
                        <p>
                            Fenomeno di distacco e movimento di masse di roccia o di terreno per azione prevalente della gravità.  Lo scuotimento connesso ad un terremoto può innescare generalmente crolli di materiali rocciosi, o scorrimenti in materiali granulari instabili, lungo pendii. I fenomeni di tipo franoso che accompagnano i terremoti, sono spesso una delle cause indotte più importanti di danno agli edifici, alle strutture e per le vite umane.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='G'">
                <ul>
                    <li>
                        <h2>GMT (Greenwich Mean Time)</h2>
                        <p>
                            In tutti i cataloghi sismici del mondo il tempo origine di un terremoto è indicato in GMT: ciò consente di paragonare nel tempo e nello spazio geografico terremoti diversi. Per poter indicare questo parametro per tutti i terremoti noti occorre trasformare molte, e spesso complicate,  indicazioni cronologiche antiche e moderne in un unico sistema. Fino al 1893, quando fu adottato in tutto il regno d’Italia il Tempo Medio dell’Europa Centrale, esistevano modi diversi di calcolare le ore del giorno. Dopo questa unificazione, il GMT risulta immediatamente dalla sottrazione di un’ora; due ore quando è in corso l’ora legale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='H'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='I'">
                <ul>
                    <li>
                        <h2>Intensità (grado di)</h2>
                        <p>
                            Grado di una scala macrosismica che classifica gli effetti di un terremoto sulle persone, sull’ambiente abitato e su quello naturale.
                        </p>
                    </li>
                    <li>
                        <h2>Ipocentro (strumentale)</h2>
                        <p>
                            Punto della crosta terrestre in cui si genera la rottura e quindi il terremoto. Generalmente è posto in profondità, in corrispondenza di una faglia. In Italia i terremoti si generano ad una profondità media di circa 10-15 km; terremoti profondi (oltre 70 km) si registrano nel Tirreno meridionale.
                        </p>
                    </li>
                    <li>
                        <h2>Isosisme</h2>
                        <p>
                            Curve che delimitano aree di uguale intensità degli effetti di un terremoto. L’area delimitata dall’isosisma di grado più elevato è detta area epicentrale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='J'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='K'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='L'">
                <ul>
                    <li>
                        <h2>Legge di attenuazione</h2>
                        <p>
                            Formula matematica con cui si calcola l’attenuazione dell’intensità con la distanza dall’epicentro macrosismico. Serve per stimare valori di intensità in località per le quali non siano disponibili descrizioni degli effetti sismici. E’ in genere determinata in modo empirico a partire dall’attenuazione rilevabile dai dati riguardanti terremoti già accaduti.
                        </p>
                    </li>
                    <li>
                        <h2>Liquefazione</h2>
                        <p>
                            Durante un terremoto, lo scuotimento di terreni costituiti da granuli di particolari dimensioni (sabbia o più piccoli), saturi d’acqua, può determinare un improvviso aumento della pressione dell’acqua contenuta nei pori con conseguente perdita di resistenza al taglio dei terreni stessi, determinando la loro liquefazione. I terreni, infatti, improvvisamente si comportano come un fluido perdendo la capacità di sostenere dei carichi, quali ad esempio edifici o altre strutture.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='M'">
                <ul>
                    <li>
                        <h2>Magnitudo</h2>
                        <p>
                            Grandezza correlata con la dimensione fisica di un terremoto. Definita per la prima volta da Charles Richter nel 1935. Dal valore della magnitudo, calcolata a partire dall’ampiezza delle onde sismiche registrate dai sismografi, è possibile risalire all’energia meccanica del terremoto. La magnitudo è disponibile, dunque, solo per i terremoti del XX secolo, dopo l’introduzione degli strumenti di registrazione; può essere calcolata anche per i terremoti più antichi a partire dall’intensità macrosismica attraverso formule empiriche.
                        </p>
                    </li>
                    <li>
                        <h2>Mercalli</h2>
                        <p>
                            vedi "Scala MCS".
                        </p>
                    </li>
                    <li>
                        <h2>Microzonazione</h2>
                        <p>
                            La microzonazione, generalmente realizzata a scala comunale, consiste nel suddividere il territorio in aree con comportamento omogeneo relativamente alla risposta sismica. Anche in un territorio arealmente limitato, infatti, le differenti caratteristiche dei terreni, soffici o rigidi, e morfologiche, ad esempio creste, orli di scarpata, possono determinare amplificazioni o attenuazioni dell’onda sismica (vedi Effetti di sito). La microzonazione è fondamentale per una corretta pianificazione del territorio.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='N'">
                <ul>
                    <li>
                        <h2>Normative antisismiche</h2>
                        <p>
                            Per ridurre gli effetti di una scossa sismica sono state stabilite delle norme tecniche da applicarsi alle nuove costruzioni ed alle costruzioni esistenti. Le norme attuali si applicano nei territori dei comuni classificati sismici e prevedono che le nuove costruzioni siano progettate e realizzate in modo da resistere senza gravi danni ai terremoti meno forti e senza crollare, salvaguardando le vite umane, ai terremoti più forti. Per gli edifici esistenti, viceversa; le norme di adeguamento e miglioramento sismico si applicano solo nei casi in cui il progetto di recupero preveda interventi sulle strutture dell’edificio (travi, solai, mura portanti).
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='O'">
                <ul>
                    <li>
                        <h2>Onde sismiche</h2>
                        <p>
                            Il processo di rottura tra due blocchi di roccia genera onde sismiche che si propagano dalla sorgente in tutte le direzioni. Vengono generati due tipi di onde: le onde di compressione (chiamate anche onde P) e le onde di taglio (chiamate anche onde S o trasversali). La differenza tra questi due tipi di onde è nel movimento del terreno. Per le onde P le particelle oscillano nella stessa direzione di propagazione dell’onda, per le onde S le particelle oscillano perpendicolarmente alla direzione di propagazione dell’onda. L’onda P viaggia a velocità più elevata ed arriva, quindi, prima delle onde S. Oltre alle onde P ed S (chiamate anche onde di volume) che viaggiano attraversando l’interno della Terra, ci sono altri tipi di onde chiamate onde superficiali, che viaggiano solo lungo la superficie terrestre. Le onde superficiali hanno una velocità inferiore a quella delle onde P ed S e rappresentano le fasi di massima ampiezza sui sismogrammi registrati a grande distanza dalla sorgente.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='P'">
                <ul>
                    <li>
                        <h2>Perocolosità sismica</h2>
                        <p>
                            La pericolosità sismica di un territorio, generalmente calcolata con metodi probabilistici, definisce la maggiore o minore probabilità che in un certo intervallo di tempo esso sia interessato da un terremoto che supera un valore stabilito dello scuotimento (intensità, accelerazione). In questo senso la pericolosità costituisce una previsione statistica del verificarsi di un terremoto (vedi Previsione). Il suo valore viene stabilito sulla base della conoscenza dei terremoti del passato, delle caratteristiche geologiche del territorio, delle modalità con cui le onde sismiche si propagano dall’epicentro al territorio in esame. La pericolosità può essere definita anche attraverso metodi deterministici, a partire dallo studio delle strutture geologiche in grado di generare i terremoti. Con la vulnerabilità e l’esposizione, determina il rischio sismico di un territorio.
                        </p>
                    </li>
                    <li>
                        <h2>Prevenzione</h2>
                        <p>
                            Prevenire il rischio sismico significa ridurre gli effetti che una scossa sismica può determinare sull’uomo, sulle costruzioni e sull’ambiente. Per raggiungere tale risultato è necessario, certamente, intervenire sulla qualità degli edifici, ma anche educare la popolazione ai comportamenti corretti da adottare prima, durante e dopo un terremoto. In Italia l’azione di prevenzione ha avuto inizio dopo il terremoto del 28 dicembre 1908 di Reggio Calabria e Messina, attraverso la classificazione del territorio e l’applicazione di speciali norme per le costruzioni. La sola applicazione del binomio “classificazione-normativa” non è tuttavia sufficiente. Una efficace prevenzione richiede una maggiore consapevolezza del cittadino sul significato del rischio e sulla sismicità del territorio, favorendo una sua partecipazione diretta al recupero dell’edilizia esistente, che per le sue caratteristiche può essere definita, in gran parte, “storica” e più vulnerabile. La prevenzione, dunque, è rappresentata da regole ma, soprattutto, da un modello culturale nuovo nei confronti del terremoto.
                        </p>
                    </li>
                    <li>
                        <h2>Previsione</h2>
                        <p>
                            Vi sono due diversi approcci: uno statistico e uno deterministico. Per previsione statistica s’intende la probabilità di occorrenza di un terremoto di una certa magnitudo, in una data area, in un determinato periodo di tempo (vedi Pericolosità). La previsione deterministica, viceversa, si basa sullo studio dei fenomeni precursori del terremoto e sulla comprensione delle leggi fisiche che regolano il comportamento di questi precursori in rapporto all’occorrenza del terremoto. Ad oggi, tuttavia, non si è ancora in grado di stabilire il tempo X, il luogo Y e la magnitudo Z del prossimo terremoto.<br>
                            L'unica previsione possibile è una previsione a carattere probabilistico, basata complessivamente sulla conoscenza della sismicità che storicamente ha interessato quel territorio e quindi sulla ricorrenza dei terremoti. Sappiamo già bene, infatti, quali sono le aree del nostro paese interessate da una elevata sismicità, per frequenza ed intensità dei terremoti, ma conosciamo meno le faglie attive che li hanno generati: molti studi si stanno  svolgendo  in questo settore per correlare i dati sui terremoti già accaduti e le singole faglie o sistemi di faglie che li hanno generati.  <br>
                            La scienza ha fatto notevoli progressi nello studio dei precursori sismici, ossia di quei parametri chimici e fisici del suolo e del sottosuolo che subiscono variazioni rilevabili prima di un terremoto. Tuttavia, le osservazioni sperimentali raccolte ancora non consentono di fare una correlazione sufficiente tra tali variazioni e l’accadere di un terremoto. Analogamente, se è vero che gli animali assumono comportamenti inusuali prima di un evento sismico, non è sempre vero che ad una particolare agitazione di cani o gatti corrisponda un terremoto. <br>
                            Per i motivi esposti è inutile attendere dei segnali premonitori o affidarsi al ‘profeta’ di turno, ma per prevenire gli effetti di una scossa sismica è necessario ridurre i fattori di rischio, agendo in particolare sulla qualità delle costruzioni.
                        </p>
                    </li>
                    <li>
                        <h2>Protezione civile</h2>
                        <p>
                            In termini generali con “protezione civile” si intendono il complesso delle attività e dei soggetti coinvolti nella fase di gestione di una emergenza. Nel corso degli anni le attività di protezione civile si stanno progressivamente orientando alla prevenzione dei rischi. In Italia, dopo il terremoto irpino del 23 novembre 1980, la necessità di migliorare la gestione delle emergenze porta alla creazione del Ministero per il Coordinamento della Protezione Civile. L’esigenza di rendere più efficace l’azione di coordinamento delle attività di previsione e prevenzione, come pure di soccorso, ispira l’emanazione della legge n. 225/92 che istituisce il Servizio Nazionale della Protezione Civile, al quale partecipano le amministrazioni dello Stato, Regioni, Province, Comuni, istituti e gruppi di ricerca scientifica, volontariato, nonché ogni altro soggetto coinvolto con le attività di protezione civile. Nel 1999, nell’ambito del generale riordino del Governo, viene istituita l’Agenzia di Protezione Civile, che attraverso un nuovo modello organizzativo, punta ad assicurare una maggiore protezione ed un più efficace soccorso alla popolazione.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='Q'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='R'">
                <ul>
                    <li>
                        <h2>Ricostruzione (fase di)</h2>
                        <p>
                            La ricostruzione dopo un terremoto rappresenta una fase chiave per una società colpita da un evento distruttivo, perché in essa si evidenziano le risposte istituzionali, sociali, culturali ed economiche al terremoto stesso, come carattere ambientale. Per le numerose scelte che la ricostruzione implica e richiede, e per gli orientamenti che vi si esprimono, si può dire che la ricostruzione delinea la risposta stessa al prossimo terremoto e rappresenta ciò che una società ritiene di dover fare per difendersi dagli eventi sismici distruttivi.   
                        </p>
                    </li>
                    <li>
                        <h2>Richter</h2>
                        <p>
                            vedi "Magnitudo".
                        </p>
                    </li>
                    <li>
                        <h2>Rischio sismico</h2>
                        <p>
                            Stima del danno atteso a seguito di un evento sismico, espressa in termini di vittime, costo economico, danno alle costruzioni. Nella definizione del rischio intervengono, oltre la pericolosità sismica, le caratteristiche di antropizzazione del territorio. Il rischio è nullo laddove non esistono edifici, beni esposti, popolazione; mentre, a parità di frequenza e di intensità dei terremoti (pericolosità), un’area densamente popolata o caratterizzata da costruzioni poco resistenti allo scuotimento di un’onda sismica, presentano un rischio elevato. Rischio elevato non è dunque sinonimo di elevata pericolosità sismica.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='S'">
                <ul>
                    <li>
                        <h2>Scala di intensità</h2>
                        <p>
                            Insieme dei quadri descrittivi di effetti rispetto ai quali vengono classificati gli effetti di un terremoto. Ai quadri descrittivi sono associati gradi di intensità di valore crescente con la gravità degli effetti. Oltre 70 scale di intensità (o macrosismiche) sono state elaborate nel mondo a partire dalle più rudimentali (quella di Jacopo Gastaldi del 1564) a quelle moderne EMS-98 (European Macroseismic Scale). Nel mondo sono in uso diverse scale di intensità: la Mercalli Modificata (MM) usata prevalentemente negli Stati Uniti, la Medvevev-Sponheuer-Karnik (MSK)  utilizzata prevalentemente nell’Europa centrale e orientale. Le scale in uso sono in genere a 12 gradi ad esclusione della scala utilizzata in Giappone (JMA) che è a 7. <br>
                            In Italia è molto usata la scala Mercalli-Cancani-Sieberg (MCS) che si presta abbastanza bene alla classificazione degli effetti desunti dai dati storici. (vedi Scala MCS).
                        </p>
                    </li>
                    <li>
                        <h2>Scala MCS</h2>
                        <p>
                            <strong>Scala Mercalli-Cancani-Sieberg (MCS)</strong>

                            <strong>I grado.</strong> Impercettibile: rilevato soltanto dagli strumenti sismici.<br><br>

                            <strong>II grado.</strong> Molto lieve: avvertito quasi esclusivamente negli ultimi piani delle case, da singole persone particolarmente impressionabili, che si trovino in assoluta quiete.<br><br>

                            <strong>III grado.</strong> Lieve: avvertito da poche persone nelle case, con vibrazioni simili a quelle prodotte da una vettura veloce, senza essere ritenuta scossa tellurica, se non dopo successivi scambi di impressioni.<br><br>

                            <strong>IV grado.</strong> Moderato: avvertito da molte persone all’interno delle case e da alcune all’aperto, senza però destar spavento, con vibrazioni simili a quelle prodotte da un pesante autotreno. Si ha lieve tremolio di suppellettili e oggetti sospesi, scricchiolio di porte e finestre, tintinnio di vetri e qualche oscillazione di liquidi nei recipienti.<br><br>

                            <strong>V grado.</strong> Abbastanza forte: avvertito da tutte le persone nelle case e da quasi tutti sulle strade, con oscillazione di oggetti sospesi e visibile movimento di rami e piante, come sotto l’azione di un vento moderato. Si hanno suoni di campanelli, irregolarità nel moto dei pendoli degli orologi, scuotimento di quadri  alle pareti, possibile caduta di qualche soprammobile leggero appoggiato alle pareti, lieve sbattimento di liquidi nei recipienti, con versamento di qualche goccia, spostamento degli oggetti piccoli, scricchiolio di mobili, sbatter di porte e finestre; i dormienti si destano, qualche persona timorosa fugge all’aperto.<br><br>

                            <strong>VI grado.</strong> Forte: avvertito da tutti con apprensione; parecchi fuggono all’aperto, forte sbattimento di liquidi, caduta di libri e ritratti dalle mensole, rottura di qualche stoviglia, spostamento di mobili leggeri con eventuale caduta di alcuni di essi, suono delle piccole campane delle chiese; in singole case crepe negli intonaci, in quelle mal costruite o vecchie danni più evidenti ma sempre innocui; possibile caduta eccezionalmente di qualche tegola o comignolo.<br><br>

                            <strong>VII grado.</strong> Molto forte: considerevoli danni, per urto o caduta, alle suppellettili, anche pesanti, delle case, suono delle grosse campane delle chiese; l’acqua di stagni e canali si agita e intorbidisce di fango, alcuni spruzzi giungono a riva; alterazioni dei livelli nei pozzi; lievi frane nei terreni sabbiosi e ghiaiosi. Danni moderati in case solide, con lievi incrinature nelle pareti, considerevole caduta di intonaci e stucchi; rottura di comignoli con caduta di pietre e tegole, parziale slittamento della copertura dei tetti; singole distruzioni in case mal costruite o vecchie.<br><br>

                            <strong>VIII grado.</strong> Distruttivo: piegamento e caduta degli alberi; i mobili più pesanti e solidi cadono e vengono scaraventati lontano; statue e sculture si spostano; talune cadono dai piedistalli. Gravi distruzioni a circa il 25% degli edifici, caduta di ciminiere, campanili e muri di cinta; costruzioni di legno vengono spostate o spazzate via. lievi fessure nei terreni bagnati o in pendio. I corsi d’acqua portano sabbia e fango.<br><br>

                            <strong>IX grado.</strong> Fortemente distruttivo: distruzioni e gravi danni a circa il 50% degli edifici. Costruzioni reticolari vengono smosse dagli zoccoli e schiacciate su se stesse; in certi casi danni più gravi.<br><br>

                            <strong>X grado.</strong> Rovinoso: distruzioni a circa il 75% degli edifici, gran parte  dei quali diroccano; distruzione di alcuni ponti e dighe; lieve spostamento delle rotaie; condutture d’acqua spezzate; rotture e ondulazioni nel cemento e nell’asfalto; fratture di alcuni decimetri nel suolo umido, frane.<br><br>

                            <strong>XI grado.</strong> Catastrofico: distruzione generale di edifici e ponti con i loro pilastri; vari cambiamenti notevoli nel terreno; numerosissime frane.<br><br>

                            <strong>XII grado.</strong> Totalmente catastrofico: ogni opera dell’uomo viene distrutta. Grandi trasformazioni topografiche; deviazioni di fiumi e scomparsa di laghi.
                        </p>
                    </li>
                    <li>
                        <h2>Sequenza sismica</h2>
                        <p>
                            Serie di scosse cronologicamente e spazialmente riconducibili al medesimo processo di rilascio di energia meccanica da parte di una medesima sorgente sismica: scossa principale seguita da repliche, scosse principali multiple, sciame sismico, ecc…
                        </p>
                    </li>
                    <li>
                        <h2>Sismografo</h2>
                        <p>
                            Strumento che registra lo spostamento, la velocità o l'accelerazione del suolo in un particolare punto della superficie terrestre. Il suo funzionamento è basato sul principio del pendolo. In seguito ad una scossa sismica, una bobina entra in oscillazione all’interno di un magnete producendo un segnale elettrico che viene poi registrato come traccia grafica. Un particolare tipo di sismografo è l’accelerografo, che misura l’accelerazione del suolo sottoposto al terremoto, fornendo utili parametri all’ingegnere progettista.
                        </p>
                    </li>
                    <li>
                        <h2>Sismologia storica</h2>
                        <p>
                            E’ il settore della sismologia che usa i dati storici (quindi qualitativi e descrittivi) per conoscere i terremoti del passato. Il suo contributo è fondamentale per conoscere l’attività sismica in corso, per localizzare le faglie attive, stimare la pericolosità e valutare il rischio sismico. Usa gli strumenti conoscitivi proprio della ricerca storica specialistica per rispondere a precise domande sismologiche. Quando si vuole allargare la finestra temporale delle osservazioni si ricorre anche all’archeologia sismica.
                        </p>
                    </li>
                    <li>
                        <h2>Sismologia strumentale</h2>
                        <p>
                            E’ il settore della sismologia che studia i terremoti attuali attraverso le tracce degli strumenti (sismografi). In Italia la Rete Sismica Nazionale Centralizzata è gestita dall’Istituto Nazionale di Geofisica, che “osserva” il territorio 24 ore su 24 raccogliendo ed elaborando le informazioni su tutti gli eventi registrati.
                        </p>
                    </li>
                    <li>
                        <h2>Sismoscopio</h2>
                        <p>
                            Strumento che rileva, senza registrarle, le oscillazioni prodotte dalle onde sismiche. A questa categoria appartenevano i primi strumenti sismici, costituiti in Italia già dal Settecento da pendoli o da recipienti a travaso di mercurio.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='T'">
                <ul>
                    <li>
                        <h2>Terremoto</h2>
                        <p>
                            E’ l’atto conclusivo di un lungo processo di accumulo di deformazione delle rocce che formano la parte più esterna della Terra. L’energia che determina questa deformazione proviene dai grandi processi dinamici che sono in corso nel nostro pianeta. Il terremoto è quindi il risultato di una frattura <i>(faglia)</i> che si propaga nelle zone crostali divenute instabili. Solo una parte dell’energia rilasciata durante un terremoto genera le onde sismiche che scuotono il terreno: il resto va a deformare in modo permanente la crosta terrestre, causandone l’abbassamento, l’innalzamento o lo spostamento orizzontale per un’area di grandezza variabile secondo l’entità del fenomeno. <br>
                            I terremoti non sono misurabili in modo diretto e in senso stretto: esistono comunque delle correlazioni che consentono di quantificare la “grandezza” di un evento sismico da due punti di vista: uno correlato all’energia <i>(magnitudo )</i>; l’altro basato sugli effetti osservati sulle costruzioni e sull’ambiente <i>(intensità)</i>. In superficie lo scuotimento dei terreni <i>(accelerazione)</i> può causare agli edifici movimenti semplificabili come orizzontali, verticali o composti, ma ovviamente essi sono a più componenti e tali da produrre in diversa misura deformazioni, torsioni, dislocazioni o crolli. 
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='U'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='V'">
                <ul>
                    <li>
                        <h2>Vulnerabilità</h2>
                        <p>
                            Definisce le caratteristiche di resistenza di una costruzione all’azione di una scossa sismica, ovvero la propensione di una struttura a subire un danno a causa di un terremoto. La vulnerabilità di una costruzione può essere espressa attraverso un indice che tiene conto delle sue caratteristiche costruttive, della qualità dei materiali utilizzati, del contesto urbano, ecc. Tra gli edifici più vulnerabili, quelli costruiti in pietrame con malta povera, che caratterizzano numerosi centri storici italiani. Si può parlare anche di vulnerabilità di un sistema infrastrutturale, come ad esempio le reti di distribuzione (life-lines) o le vie di comunicazione.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='W'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='X'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='Y'">
                <ul>
                    <li>
                        <p>
                            Nessun termine con questa iniziale.
                        </p>
                    </li>
                    
                </ul>
            </div>
            <div v-if="this.currentLetter=='Z'">
                <ul>
                    <li>
                        <h2>Zonazione sismica</h2>
                        <p>
                            La zonazione sismogenetica, in passato definita anche zonazione sismica, consiste nell’individuare sul territorio aree omogenee nei meccanismi di generazione dei terremoti. Una zona sismogenetica rappresenta la proiezione in superficie del sistema di faglie (faglia principale e faglie associate) capaci di generare un terremoto ed omogenee nel loro comportamento cinematico, ossia nelle caratteristiche del movimento relativo dei blocchi a contatto. La zonazione sismogenetica è necessaria per la valutazione della pericolosità sismica del territorio.
                        </p>
                    </li>
                    
                </ul>
            </div>





        </div>
    </div>

  
  <div id="footer" class="marquee">
      
    </div>
</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Glossario',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
        currentLetter : 'A',
        currentWord : ''
      }
    },
    mounted() {
      this.computedHeight = this.windowHeight+'px';
      console.log(this.windowHeight);
    },
    computed: {
      
      
    },
    methods: {
        setCurrentWord(word){
            this.currentWord = word;
        },
        changeCurrentLetter(letter){
            this.currentLetter = letter;
        },
       goWhere(destination){
            let whereReturn = localStorage.getItem('whereicomefrom');
            //localStorage.setItem('whereicomefrom', 'glossario');
            if(destination=='back'){
                router.push('/'+whereReturn);
            }else{
                router.push('/'+destination);
            }
             
       },
       myEventHandler(e) {
            // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            //this.player.height(e.target.innerHeight); 
            this.computedHeight = e.target.innerHeight+'px';
      },
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
  }
</script>

<style scoped>
.glosButtonVoid{
    background-color: grey !important;
}
.glosButtonA{
    cursor: pointer;
    margin-right: 3px;
    background-color: steelblue;
    font-weight: bold;
    font-size: 1.5em;

}
.glosButtonB{
    cursor: pointer;
    margin-right: 3px;
   
    font-weight: bold;
    font-size: 1.5em;

}

.listButton{
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 1.2em;
    margin-bottom: 10px;
}

li{
    display: table-row;
    text-align: left;
}

p{
    font-size: 1.4em;
}

</style>