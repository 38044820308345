<template>

<div class="start"> <!-- MAIN CONTAINER -->

  <video-player  class="video-player-box"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
 
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 >
  </video-player>
  
   <div id="nav" class="marquee">
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div style="position: fixed; top: 0px; right: 30px; z-index: 100;">
            <router-link to="/about"><img src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" /></router-link>
        </div>

    </div>

  <div id="footer" class="marquee">
      <p>Yoooo!!
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        <button @click="playOrNot()">asasaasa {{this.videoStatus}} --</button>
      </p>
    </div>
</div>
  
</template>

 
<script>
  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Titolo qui',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        playerOptions: {
          // videojs options
          muted: false,
          controls: false,
          autoplay: true,
          language: 'it',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: "video/Intro.mp4"
          }],
          //poster: "/public/img/mstile-150x150.png",
        }
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)
      
      //this.player.height(this.Height); 

      this.player.width(this.windowWidth);
      this.player.height(this.windowHeight); 
      //this.player.height(this.windowHeight); 
      
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      },
      
    },
    methods: {
      playOrNot(){
          //this.player.play();
          if(this.videoStatus=='START' || this.videoStatus=='PAUSE' || this.videoStatus=='ENDED'){
              console.log('yo');
              this.player.play();
          }

          if(this.videoStatus=='PLAY'){
              console.log('yo');
              this.player.pause();
          }
          
          console.log('sdsdsd');
      },
      // listen event
      onPlayerPlay(player) {
         console.log('player play!', player);
         this.videoStatus = 'PLAY';
      },
      onPlayerPause(player) {
         console.log('player pause!', player);
         this.videoStatus = 'PAUSE';
      },
      onPlayerEnded(player) {
         console.log('player ended!', player);
         this.videoStatus = 'ENDED';
      },
      onPlayerLoadeddata(player) {
         console.log('player Loadeddata!', player);
      },
      onPlayerWaiting(player) {
         console.log('player Waiting!', player);
      },
      onPlayerPlaying(player) {
         console.log('player Playing!', player);
      },
      onPlayerTimeupdate(player) {
         console.log('player Timeupdate!', player.currentTime());
      },
      onPlayerCanplay(player) {
         console.log('player Canplay!', player);
      },
      onPlayerCanplaythrough(player) {
         console.log('player Canplaythrough!', player);
      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState);
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player);
        player.currentTime(0);
        // console.log('example 01: the player is readied', player)
      },
       myEventHandler(e) {
            // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            //this.player.height(e.target.innerHeight); 
            this.player.width(e.target.innerWidth);
      },
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
  }
</script>