<template>

<div class="start sf" id="mainP" :style="{ height: computedHeight }"> <!-- MAIN CONTAINER -->


    
   <div id="nav" class="marquee">
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhere('back')" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario')" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori')" src="img/autori.png" class="rightmastericons" />
        </div>

    </div>


    <div ref="authors" style="position: absolute;
    top: 70px;
    text-align: center;
    margin-left: 50px;
    margin-right: 120px;
    padding-bottom: 120px;
    width: -webkit-fill-available;">
        
        <div>
            <div >
                <p>
                    Il presente Terragiornale è la seconda versione realizzata integrando e rivedendo un prodotto multimediale DVDRom commissionato nel 2007 alla SGA Storia Geofisica Ambiente dal Comune di Pertosa che ne autorizzò la videoproiezione nei soli musei MIdA.<br>
                    La presente versione ha esteso l’arco cronologico ai terremoti dell’Emilia del 2012 e rivisto l’intera interfaccia proponendola per l’ambiente web. Nel rispetto dei crediti della precedente versione, a fianco di ogni contributo è indicato l’anno o gli anni di riferimento. <br><br>

                    <strong>ideazione, coordinamento e controllo</strong>
                    Graziano Ferrari<br><br>

                    <strong>progettazione e sceneggiatura dei terragiornali</strong><br>
                    Graziano Ferrari (2007 e 2020) e Maria Pia De Natale (2007) <br><br>

                    <strong>redazione dei testi</strong><br>
                    Graziano Ferrari (2020) e Maria Pia De Natale (2007) <br><br>

                    <strong>fonti, elaborati di sismologia storica</strong><br>
                    Guidoboni E., Ferrari G., Mariotti D., Comastri A., Tarabusi G., Sgattoni G., Valensise G. (2018) - CFTI5Med, Catalogo dei Forti Terremoti in Italia (461 a.C.-1997) e nell’area Mediterranea (760 a.C.-1500). Istituto Nazionale di Geofisica e Vulcanologia (INGV). doi: https://doi.org/10.6092/ingv.it-cfti5<br><br>

                    <strong>iconografia</strong><br>
                    archivi SGA Storia Geofisica Ambiente srl<br><br>

                    <strong>scelta dell'iconografia</strong><br>
                    Maria Giovanna Bianchi e Alberto Comastri (2007), Graziano Ferrari (2020) <br><br>

                    <strong>ricerca fonti video</strong><br>
                    Graziano Ferrari (2007 e 2020), Maria Pia De Natale (2007) <br><br>

                    <strong>grafica</strong><br>
                    Stefano Fabbri (2020) e Maria Giovanna Bianchi (2007) <br><br>

                    <strong>sviluppo multimediale</strong><br>
                    Stefano Fabbri (2007 e 2020) e Maria Giovanna Bianchi (2007) <br><br>

                    <strong>voci</strong><br>
                    Annagiulia Belletti<br>
                    Umberto Bortolani<br>
                    Henry Paul Monaco<br>
                    Massimo Rossi<br>
                    Federica Navarria<br><br>

                    <strong>modellazione 3D</strong><br>
                    Marco Gualdrini GEOgrafica - Faenza, Claus Larsen<br><br>

                    <strong>realizzazione audio sigla</strong><br>
                    Antonino De Natale<br><br>

                    © Fondazione MIdA<br>
                    www.fondazionemida.com
                    <br><br>




                </p>
            </div>
        </div>
    </div>

  <div id="footer" class="marquee">
      
  </div>

</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Autori',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
      }
    },
    mounted() {
      this.computedHeight = this.windowHeight+'px';
      console.log(this.windowHeight);
    },
    computed: {
      
      
    },
    methods: {
       goWhere(destination){
            let whereReturn = localStorage.getItem('whereicomefrom');
            //localStorage.setItem('whereicomefrom', 'autori');
            if(destination=='back'){
                router.push('/'+whereReturn);
            }else{
                router.push('/'+destination);
            }
             
       },
       myEventHandler(e) {
            // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            //this.player.height(e.target.innerHeight); 
            this.computedHeight = e.target.innerHeight+'px';
      },
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
  }
</script>

<style scoped>

.glosButtonA{
    cursor: pointer;
    margin-right: 3px;
    background-color: burlywood;
    font-weight: bold;
    font-size: 1.5em;

}
.glosButtonB{
    cursor: pointer;
    margin-right: 3px;
   
    font-weight: bold;
    font-size: 1.5em;

}

.listButton{
    border: none;
    background: transparent;
    cursor: pointer;
    font-size: 1.2em;
    margin-bottom: 10px;
}

li{
    display: table-row;
    text-align: left;
}

p{
    font-size: 1.4em;
}

</style>