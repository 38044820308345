<template>

<div class="start sf" id="mainP" :style="{ height: computedHeight }"> <!-- MAIN CONTAINER -->


  <transition name="slide-fade" >
    <span v-if="show01"  @click="goWhere('t01')" class="terremotiArrow" style="mposition:absolute; top:100px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal V secolo a.C. al V d.C. </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show02"  @click="goWhere('t02')" class="terremotiArrow" style="mposition:absolute; top:145px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal VI all'XI secolo </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show03"  @click="goWhere('t03')" class="terremotiArrow" style="mposition:absolute; top:190px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal XII al XIII secolo </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show04"  @click="goWhere('t04')" class="terremotiArrow" style="mposition:absolute; top:235px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal XIV secolo alla seconda metà del XV </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show05"  @click="goWhere('t05')" class="terremotiArrow" style="mposition:absolute; top:280px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal 1454 alla metà del XVI secolo </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show06"  @click="goWhere('t06')" class="terremotiArrow" style="mposition:absolute; top:325px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dalla metà del XVI secolo al 1713 </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show07"  @click="goWhere('t07')" class="terremotiArrow" style="mposition:absolute; top:370px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal 1714 al 1814 </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show08"  @click="goWhere('t08')" class="terremotiArrow" style="mposition:absolute; top:415px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal 1815 al 1859 </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show09"  @click="goWhere('t09')" class="terremotiArrow" style="mposition:absolute; top:460px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal 1860 al 1917 </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show10"  @click="goWhere('t10')" class="terremotiArrow" style="mposition:absolute; top:505px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Dal 1918 al 1990 </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show11"  @click="goWhere('t11')" class="terremotiArrow" style="mposition:absolute; top:550px; z-index:20; cursor:pointer; position: absolute; ">
      <div>1997 Umbria </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show12"  @click="goWhere('t12')" class="terremotiArrow" style="mposition:absolute; top:595px; z-index:20; cursor:pointer; position: absolute; ">
      <div>2009 L'Aquila </div>
    </span>
  </transition>
  <transition name="slide-fade" >
    <span v-if="show13"  @click="goWhere('t13')" class="terremotiArrow" style="mposition:absolute; top:640px; z-index:20; cursor:pointer; position: absolute; ">
      <div>2012 Emilia </div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="show13" class="terremotiArrowxx" style="mposition:absolute; top:721px; z-index:20; cursor:pointer; position: absolute; ">
      <div style="visibility: hidden;">------</div>
    </span>
  </transition>

<div>
  <img src="img/sfondo_home.jpg" :style="{ height: computedHeightImg, 'margin-top': '55px', width: computedWidthImg }" />
</div>
    
   <div id="nav" class="marquee">
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhere('')" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario')" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori')" src="img/autori.png" class="rightmastericons" />
        </div>



    </div>

  <div id="footer" class="marquee">
      <p>
        
         
        
      </p>
    </div>
</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Terra giornale: cronache sismiche dal pianeta terra',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
        computedHeightImg : '200px',
        computedWidthImg : '200px',
        show01:false,
        show02:false,
        show03:false,
        show04:false,
        show05:false,
        show06:false,
        show07:false,
        show08:false,
        show09:false,
        show10:false,
        show11:false,
        show12:false,
        show13:false,
      }
    },
    mounted() {
      this.computedHeight = this.windowHeight+'px';
      this.computedHeightImg = (this.windowHeight-60)+'px';
      this.computedWidthImg = this.windowWidth+'px';

      if(this.windowHeight>this.windowWidth){
        this.computedHeightImg = 'auto';
      }else{
        this.computedWidthImg = 'auto';
      }
      console.log(this.windowHeight);
    },
    computed: {
      
      
    },
    methods: {
       setTerremotiVisible(num){
         console.log(num);
         if(num<14){
           if(num==1){
             this.show01 = true;
           }
           if(num==2){
             this.show02 = true;
           }
           if(num==3){
             this.show03 = true;
           }
           if(num==4){
             this.show04 = true;
           }
           if(num==5){
             this.show05 = true;
           }
           if(num==6){
             this.show06 = true;
           }
           if(num==7){
             this.show07 = true;
           }
           if(num==8){
             this.show08 = true;
           }
           if(num==9){
             this.show09 = true;
           }
           if(num==10){
             this.show10 = true;
           }
           if(num==11){
             this.show11 = true;
           }
           if(num==12){
             this.show12 = true;
           }
           if(num==13){
             this.show13 = true;
           }
           setTimeout(() => this.setTerremotiVisible(num+1), 500);
         }
       },
       goWhere(destination){
         localStorage.setItem('whereicomefrom', 'home');
         router.push('/'+destination);
       },
       myEventHandler(e) {
            // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            //this.player.height(e.target.innerHeight); 
            this.windowWidth = e.target.innerWidth;
            this.computedHeight = e.target.innerHeight+'px';
            this.computedHeightImg = (e.target.innerHeight-60)+'px';
            this.computedWidthImg = this.windowWidth+'px';

            if(e.target.innerHeight>e.target.innerWidth){
              this.computedHeightImg = 'auto';
            }else{
              this.computedWidthImg = 'auto';
            }
      },
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        setTimeout(() => this.setTerremotiVisible(1), 500);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler); 
    },
  }
</script>
<style scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active { 
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .terremotiArrow{
    left: -10px;
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrow div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.7em;
  }
</style>