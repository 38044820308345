<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>

<script>
import Vue from 'vue';
import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css';
Vue.use(VueVideoPlayer, /* {
  options: global default options,
  events: global videojs events
} */
)
export default {
  
}
</script>

<style lang="scss">
body{
  margin:0px;
  background-image: url('/img/sfondo_video.jpg');
      background-size: contain;
}

.sfxx{
      background-image: url('/img/sfondo_video.jpg');
      background-size: contain;
    }

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 15px;
  height: 30px;
  background-image: linear-gradient(red, #ff000063 50%, red);

  position: absolute;
  top: 0px;
  z-index: 2;
  //width: 100%;
  width: -webkit-fill-available;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

#footer{
  padding: 15px;
  background-image: linear-gradient(red, #ff000063 50%, red);
  height: 20px;

  position: fixed;
  bottom: 0px;
  //width: 100%;
  width: -webkit-fill-available;
}


// text scrolling 
 .marquee {
            
            overflow: hidden;
            position: relative;
            background: #fefefe;
            color: #333;
           
        }
        
        .marquee p {
            position: absolute;
            width: 200%;
            height: 100%;
            top: 0px;
            line-height: 20px;;
            font-weight: bold;
            font-size: 0.9em !important;
            color: black;

            text-align: center;
            -moz-transform: translateX(100%);
            -webkit-transform: translateX(100%);
            transform: translateX(100%);
            -moz-animation: scroll-left 70s linear infinite;
            -webkit-animation: scroll-left 70s linear infinite;
            animation: scroll-left 70s linear infinite;
        }
        
        
        @-moz-keyframes scroll-left {
            0% {
                -moz-transform: translateX(100%);
            }
            100% {
                -moz-transform: translateX(-100%);
            }
        }
        
        @-webkit-keyframes scroll-left {
            0% {
                -webkit-transform: translateX(100%);
            }
            100% {
                -webkit-transform: translateX(-100%);
            }
        }
        
        @keyframes scroll-left {
            0% {
                -moz-transform: translateX(100%);
                -webkit-transform: translateX(100%);
                transform: translateX(100%);
            }
            100% {
                -moz-transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
            }
        }

.rightmastericons{
  max-width: 65px;
}

.wordlicon{
  position: fixed; top: 70px; right: 30px; z-index: 100; cursor:pointer;
}

.glossarioicon{
  position: fixed; top: 140px; right: 35px; z-index: 100; cursor:pointer;
}

.autoriicon{
  position: fixed; top: 205px; right: 35px; z-index: 100; cursor:pointer;
}

.documentiicon{
  position: fixed; top: 275px; right: 35px; z-index: 100; cursor:pointer;
}

.testodoc{
  position: absolute;
    top: 70px;
    text-align: left;
    margin-left: 130px;
    margin-right: 120px;
    margin-bottom: 50px;
    padding-bottom: 200px;
    width: -webkit-fill-available;
}

.glossariocontainer{
    position: absolute;
    top: 70px;
    text-align: center;
    margin-left: 50px;
    margin-right: 120px;
    padding-bottom: 120px;
    width: -webkit-fill-available;
}

@media only screen and (max-width: 768px) {
  /* For desktop: */
  .rightmastericons{
    max-width: 35px;
  }

  .wordlicon{
    position: fixed; top: 70px; right: 35px; z-index: 100; cursor:pointer;
  }

  .glossarioicon{
    position: fixed; top: 105px; right: 35px; z-index: 100; cursor:pointer;
  }

  .autoriicon{
    position: fixed; top: 145px; right: 35px; z-index: 100; cursor:pointer;
  }

  .documentiicon{
    position: fixed; top: 185px; right: 35px; z-index: 100; cursor:pointer;
  }

  .testodoc{
    position: absolute;
    top: 70px;
    text-align: left;
    margin-left: 10px;
    margin-right: 120px;
    margin-bottom: 50px;
    padding-bottom: 200px;
    width: -webkit-fill-available;
  }

  .testodoc p{
    font-size: 1.1em;
  }

  .glossariocontainer{
    position: absolute;
    top: 70px;
    text-align: center;
    margin-left: 5px;
    margin-right: 90px;
    padding-bottom: 120px;
    width: -webkit-fill-available;
}

}

</style>
