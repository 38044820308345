<template>



<div class="intro" @click="saltaIntro()" @mouseup="saltaIntro()"> <!-- MAIN CONTAINER -->

<button v-if="showStartButton" style="position: absolute; z-index: 999; top: 30%; left: 50%; background: transparent; border: none; cursor:pointer;">
  <div style="position: relative; color: white; top: 67px; font-size: 1.8em; right: 80px; text-align: left;">Terra <br>giornale</div>
  <img src="img/Earth-spinning-rotating-animation-40.gif" style="max-width: 120px;" />
  </button>

  <video-player  class="video-player-box2"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
 
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 >
  </video-player>

    <!-- 
      You can disable entirely the autoplay policy by using an internal switch with chrome.exe --autoplay-policy=no-user-gesture-required. This allows you to test your website as if user were strongly engaged with your site and playback autoplay would be always allowed.

      You can also decide to make sure playback autoplay is never allowed by disabling use of MEI, applying autoplay policy to Web Audio, and whether sites with the highest overall MEI get playback autoplay by default for new users. This can be done with three internal switches with chrome.exe --disable-features=PreloadMediaEngagementData, MediaEngagementBypassAutoplayPolicies.
    
      https://developers.google.com/web/updates/2017/09/autoplay-policy-changes
    -->

</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Intro',
        videoStatus : 'START',
        isPlaying : false,
        showStartButton : false,
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        playerOptions: {
          // videojs options
          muted: false,
         
          controls: false,
          autoplay: false,
          language: 'it',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: "video/Intro.mp4"
          }],
          //poster: "/public/img/mstile-150x150.png",
        }
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)
      
      //this.player.height(this.Height); 

      this.player.width(this.windowWidth);
      this.player.height(this.windowHeight); 
      //this.player.height(this.windowHeight); 
      
    },
    computed: {
      player() {
        return this.$refs.videoPlayer.player
      },
      
    },
    methods: {
      saltaIntro(){
          this.playOrNot();
          //alert('yo');
      },
      isItPlaying(){
          if(this.isPlaying==false){
             this.showStartButton = true;
             
         }
      },
      playOrNot(){
          //this.player.play();
          if(this.videoStatus=='START' || this.videoStatus=='PAUSE' || this.videoStatus=='ENDED'){
              console.log('yo');
              this.player.play();
          }

          if(this.videoStatus=='PLAY'){
              console.log('yo');
              //this.player.pause();
              router.push('/home');
          }
          
          console.log('sdsdsd');
      },
      // listen event
      onPlayerPlay(player) {
         console.log('player play!', player);
         this.videoStatus = 'PLAY';
      },
      onPlayerPause(player) {
         console.log('player pause!', player);
         this.videoStatus = 'PAUSE';
      },
      onPlayerEnded(player) {
         console.log('player ended!', player);
         this.videoStatus = 'ENDED';
         router.push('/home');
      },
      onPlayerLoadeddata(player) {
         console.log('player Loadeddata!', player);
      },
      onPlayerWaiting(player) {
         console.log('player Waiting!', player);
      },
      onPlayerPlaying(player) {
         console.log('player Playing!', player);
         this.isPlaying = true;
      },
      onPlayerTimeupdate(player) {
         console.log('player Timeupdate!', player.currentTime());
         this.showStartButton = false;
         if(player.currentTime()>5){
             //player.muted(false);
             //player.play();
         }
         
      },
      onPlayerCanplay(player) {
         console.log('player Canplay!', player);
         player.play();
      },
      onPlayerCanplaythrough(player) {
         console.log('player Canplaythrough!', player);
         console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx=>'+this.videoStatus);
         console.log('xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx=>'+this.isPlaying+'kkkkk'+player.currentTime());
         if(this.isPlaying==false){
             //this.showStartButton = true;
             
         }
         //player.pause();
         
         
      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState);
         
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player);
        player.currentTime(0);
        // console.log('example 01: the player is readied', player)
        
      },
       myEventHandler(e) {
            // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            //this.player.height(e.target.innerHeight); 
            this.player.width(e.target.innerWidth);
      },
    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        setTimeout(() => this.isItPlaying(), 2000);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
    },
  }
</script>

<style >
.video-js {
    background-image: none;
    background-size: contain;
    background-color: black !important;
}
</style>