<template>

<div class="start sf"> <!-- MAIN CONTAINER -->

  <div v-if="cosaRiproduco==1">
    <img src="img/t08.jpg" :style="{ height: computedHeightImg, 'margin-top': '55px', width: computedWidthImg }" />
  </div>

  <div v-if="cosaRiproduco==4" ref="authors" class="testodoc">
    <p>
        <strong>XIX secolo  - Cultura</strong><br><br>
        Questo secolo è segnato da grandi passioni politiche e da grandi rivoluzioni tecnologiche. La prima metà dell'Ottocento è costituita da decenni generosi quanto utopici, in cui diverse popolazioni inseguono un sogno di indipendenza dai poteri centrali dei grandi imperi europei. La tempesta napoleonica e le varie temperie nazionaliste trasformano gli assetti politici, l'industrializzazione trasforma gli assetti sociali, e tutti i moti e gli slanci causati dai cambiamenti in atto confluiscono in un panorama culturale vasto, drammatico, spazzato da venti di opposti idealismi.<br>
La cultura continua ad essere appannaggio di una élite, nonostante la conclamata riscoperta del "popolo". La decisa tendenza dell'economia verso il modello capitalista, conseguente all'industrializzazione, rende instabili e problematici gli equilibri sociali. L'Ottocento è un secolo inquieto, in cui le varie burocrazie e le gerarchie del potere si sviluppano e si organizzano anche per la necessità di tenere sotto controllo le tensioni che percorrono gli Stati.<br>
In Italia l'organizzazione degli Stati napoleonici introduce nelle burocrazie elementi nuovi, più razionali, lasciando un segno permanente nell'assetto dei poteri territoriali. La notevole efficienza burocratica della dominazione asburgica deve confrontarsi con gli sconvolgimenti dei moti indipendentisti che portano nel 1861 all'unificazione dell'Italia sotto la monarchia dei Savoia.<br>
Una maggiore razionalità influenza le strutture di controllo dell'emergenza e di programmazione della ricostruzione che creano un prezioso serbatoio di esperienza e di informazioni per il futuro. Con il regno d'Italia scompaiono anche tradizioni e usi amministrativi precedenti, ma non sempre è un segno positivo. Per quanto riguarda i terremoti, infatti, alcune aree "retrocedono": in quella che costituiva il granducato di Toscana, per esempio, si perdono la precedente efficienza e precisione nella rilevazione dei danni. <br><br>
        <br><br><strong>Scienza</strong><br><br>
        E' il secolo in cui nasce e si struttura la sismologia come scienza, nell'accezione corrente del termine. Si mettono a punto le prime scale macrosismiche, per la classificazione degli effetti dei terremoti, e si introducono strumenti e metodi di rilevazione strumentale sempre più perfezionati. Soprattutto nella seconda parte del secolo si struttura una rete di osservazione scientifica, strumentale e non, dei terremoti <br>
Ancora nei primi decenni del secolo alcuni autori fanno riferimento alla teoria elettricista (diffusa nel Settecento) mentre si afferma progressivamente la teoria del "vulcanesimo", introdotta nel 1760 da John Mitchell (1724-1793), professore nel Collegio della Regina a Cambridge.<br>
Nel corso dell'Ottocento maturano alcuni indirizzi teorici sull'origine dei terremoti, talvolta solo accennati e non sviluppati nei secoli precedenti. In numerosi paesi, prima fra tutti l'Italia, cresce la consapevolezza dell'importanza di delineare una geografia dei terremoti: si producono i primi cataloghi per creare una cronologia degli eventi, talvolta elencati assieme ad altre calamità naturali, perché il quadro teorico in cui avvengono queste compilazioni non è ancora chiaro agli stessi autori. Solo nell'ultimo quarto del secolo la costruzione dei cataloghi dei terremoti è orientata alle prime delineazioni delle aree sismiche. <br>
L'osservazione del terremoto "sul campo" si fa più specializzata e si realizzano vere e proprie spedizioni scientifiche nelle aree colpite da terremoti distruttivi, sul modello di quelle che venivano fatte per rilevare i caratteri geologici di un territorio. Ne sono esempi la ricognizione di Leopoldo Pilla nell'area del terremoto del 14 agosto 1846 nell'entroterra pisano. Agli elementi di descrizione dei danni sono intercalate osservazioni sulla natura geologica dei terreni, su cui sono fondati i diversi paesi colpiti. <br>
Robert Mallet nel 1858, ma prima e dopo di lui Julius Schmidt (1847) e Karl Seebach (1873), tentano applicazioni matematiche ad alcuni dati dell'osservazione diretta degli effetti di terremoto come la direzione di emergenza delle onde sismiche e i tempi di arrivo.<br>
Nella sua ponderosa opera The great neapolitan earthquake of 16th December 1857. The first principles of observational seismology Robert Mallet (1862), non solo coniò il termine di "sismologia" per la scienza dei terremoti, ma introdusse un primo approccio quantitativo all'analisi degli effetti dei terremoti. In questo suo tentativo di integrare le più avanzate teorie matematiche con i dati dell'osservazione sugli effetti sismici, Mallet non mancò di criticare apertamente quegli studiosi che si erano fatti scappare importanti occasioni di speculazione scientifica sugli effetti dei terremoti che andavano accadendo e di cui erano testimoni diretti. <br>
Numerosi sono poi gli studiosi che visitano la Liguria occidentale devastata dal terremoto del febbraio del 1887, lasciando importanti descrizioni. <br>
<br>
Come per altre discipline, anche per la sismologia la comparsa degli strumenti segna un momento importante nella nascita e nel diffondersi di una specifica scienza dei terremoti. Già in occasione dei maggiori terremoti del XVIII secolo (1703 in Italia Centrale, 1731 in Puglia, 1751 in Umbria e 1783 in Calabria) numerosi studiosi, soprattutto italiani, progettarono semplici strumenti avvisatori basati sui principi del pendolo o del travaso di liquidi. Con il sismoscopio a pendolo rovescio di Forbes (1847) e il sismografo elettromagnetico realizzato nel 1856 da Luigi Palmieri, direttore dell'Osservatorio Vesuviano di Napoli, si avvia una seconda fase nella storia della sismometria.<br>
Con il miglioramento della sensibilità degli strumenti, che divengono capaci di rilevare terremoti normalmente non percepiti dalle persone, si apre una speranza nuova per la sismologia: registrare i piccoli terremoti per prevedere quelli disastrosi. <br>
<br>
Nell'ultimo quarto del secolo, il succedersi di terremoti disastrosi e un progressivo interesse da parte di studiosi di diversa estrazione culturale e sociale portano alla creazione di reti di osservazione permanenti molto articolate sul territorio e veri e propri servizi geodinamici nazionali. Questi ultimi hanno il compito di gestire reti di osservazione meteorologica e sismica, strumentale e non, e di promuovere la progettazione e l'installazione di nuova strumentazione. La raccolta dei dati relativi agli effetti dei terremoti sulle persone, sugli edifici e sull'ambiente viene condotta in maniera sistematica per mezzo di questionari macrosismici (dette "cartoline macrosismiche") <br>
<br>
Nell'ultimo quarto del secolo si assiste al frenetico succedersi di iniziative che testimoniano la grande vitalità della giovane disciplina sismologica. Nel 1874, su iniziativa di Michele Stefano de Rossi, un pioniere della sismologia attuale, nasce in Italia la prima rivista al mondo di geodinamica: il Bullettino del Vulcanismo italiano. La vasta circolazione di conoscenza che garantiva questa rivista, durata fino al 1897, suscita emulazione e ha subito un seguito in altre analoghe riviste in Italia e all'estero. Sul finire del secolo, dopo una fase pionieristica durata 50 anni, la sismologia può dirsi strutturata come una scienza, con i suoi strumenti, le sedi di confronto nazionali e internazionali, le riviste e i servizi di raccolta e di sistematizzazione dei dati. Importanti percorsi di ricerca sono stati tracciati: geografia sismica, previsione, mappa degli propagazione degli effetti, aprendo nuove prospettive per il XX secolo.<br>
La grande quantità di dati che si vanno sedimentando sugli effetti dei terremoti, sulla base delle osservazioni sia umane sia strumentali, evidenziano le contraddizioni delle varie teorie interpretative che attraversano il XIX secolo: convive per alcuni aspetti la settecentesca teoria elettricista, con quella del vulcanesimo, talvolta arricchita addirittura da riferimenti presi dalla teoria aristotelica dei venti sotterranei.
    </p>
  </div>
  
  <transition name="slide-fade" >
    <span ref="a1857" v-if="showLink" @click="startVideo(2); testoCheScorre='Muore Carlo Pisacane, patriota e scrittore &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Victor Hugo scrive \'I miserabili\', Baudelaire \'I fiori del male\', Flaubert \'Madame Bovary\', Tolstoj \'Guerra e pace\' '" class="terremotiArrow" style="mposition:absolute; top:70px; z-index:20; cursor:pointer; position: absolute; ">
      <div>1857</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="showDeepening" @click="startVideo(3)" class="terremotiArrow" style="mposition:absolute; top:120px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Approfondimento</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="showDeepening" @click="startVideo(5)" class="terremotiArrow" style="mposition:absolute; top:170px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Approfondimento</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="showDeepening" @click="startVideo(6)" class="terremotiArrow" style="mposition:absolute; top:220px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Mallett e Bernoud</div>
    </span>
  </transition>

    <video-player :class="videoPlayerBox"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
 
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 >
  </video-player>

   <div id="nav" class="marquee" >
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhereGlobe(); testoCheScorre=''" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario'); testoCheScorre=''" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori'); testoCheScorre=''" src="img/autori.png" class="rightmastericons" />
        </div>

        <div class="documentiicon">
            <img @click="cosaRiproduco=4; goToDocument(); testoCheScorre=''" src="img/documenti-icon.png" class="rightmastericons" />
        </div>

    </div>

  <div id="footer" class="marquee" >
      <p v-html="testoCheScorre">
        
        
      </p>
    </div>

</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Dal 1815 al 1859 ',
        testoCheScorre : '',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
        computedHeightImg : '200px',
        computedWidthImg : '200px',
        audioFile : 'audio/08.mp3',
        videoPlayerBox : 'video-player-box notvisible',
        audioCanPlay : false,
        audioObject : null,
        cosaRiproduco : 1,
        cosaRiproducoPosizione : 0,
        showLink : false,
        showDeepening : false,
        reStart : false,
        playerOptions: {
          // videojs options
          muted: false,
          controls: false,
          autoplay: false,
          language: 'it',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: "video/1857/1857.mp4"
          }],
          //poster: "/public/img/mstile-150x150.png",
        }
        
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)

      this.computedHeight = this.windowHeight+'px';
      this.computedHeightImg = (this.windowHeight-60)+'px';
      this.computedWidthImg = this.windowWidth+'px';

      if(this.windowHeight>this.windowWidth){
        this.computedHeightImg = 'auto';
      }else{
        this.computedWidthImg = 'auto';
      }
      console.log(this.windowHeight);

      this.player.width(this.windowWidth);
      this.player.height(this.windowHeight-110); 
      
    },
    computed: {
     player() {
        return this.$refs.videoPlayer.player
      },
      
    },
    methods: {
      startVideo(what){
          this.cosaRiproduco = what;
          this.videoPlayerBox = 'video-player-box yesvisible';
          console.log(this.videoPlayerBox);
          // il primo video
          if(what==2){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = 0;
              this.player.src("video/1857/1857.mp4");
              this.player.currentTime(120);
              this.player.play();
              console.log(this.player.currentTime());
          }
          if(what==3){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1857/1857_T01/1857_T01.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }
          if(what==5){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1857/1857_T02/1857_T02.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }
          if(what==6){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1857/1857_T03/1857_T03.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }
          this.changeSlidingText();

      },
      goToDocument(){
        this.stopSound(this.audioFile);
        this.videoPlayerBox = 'video-player-box notvisible';
        this.cosaRiproducoPosizione = this.player.currentTime();
        this.player.pause();
      },
      goWhereGlobe(){
          // resetto e torno indietro #########################################
          console.log(this.cosaRiproduco+'<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
            if(this.cosaRiproduco==1){
                router.push('/home');
            }

            if(this.cosaRiproduco==2){
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
                //this.cosaRiproducoPosizione = 0;
            }

            if(this.cosaRiproduco==3 || this.cosaRiproduco==5 || this.cosaRiproduco==6){
                this.cosaRiproduco=2;
                this.player.pause();
                this.player.src("video/1857/1857.mp4");
                //this.player.currentTime(this.cosaRiproducoPosizione);
                this.player.play();
                
            }

            if(this.cosaRiproduco==4){ 
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
            }

            console.log(this.cosaRiproduco);
            this.changeSlidingText();
       },
      goWhere(destination){
            //let whereReturn = localStorage.getItem('whereicomefrom');
            localStorage.setItem('whereicomefrom', 't08');
            if(destination=='back'){
                router.push('/home');
            }else{
                router.push('/'+destination);
            }
             
       },
       // listen event
      onPlayerPlay(player) {
         console.log('player play!'+ player + 'ppp' + parseInt(this.cosaRiproducoPosizione));
         if(this.cosaRiproduco==2){player.currentTime(parseInt(this.cosaRiproducoPosizione));}else{ player.currentTime(0); }
         this.videoStatus = 'PLAY';
      },
      onPlayerPause(player) {
         console.log('player pause!', player);
         this.videoStatus = 'PAUSE';
      },
      onPlayerEnded(player) {
         console.log('player ended!', player);
         this.videoStatus = 'ENDED';
         this.goWhereGlobe();
      },
      onPlayerLoadeddata(player) {
         console.log('player Loadeddata!', player);
      },
      onPlayerWaiting(player) {
         console.log('player Waiting!', player);
      },
      onPlayerPlaying(player) {
         console.log('player Playing!', player);
         
      },
      onPlayerTimeupdate(player) {
          //if(player.currentTime()<30){player.currentTime(30)}
          //if(this.cosaRiproduco==2 && this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(30);  console.log('dio');  }

         console.log('player Timeupdate!'+ player.currentTime() + '---' + parseInt(this.cosaRiproducoPosizione) + '|||' + this.reStart);
         if(this.cosaRiproduco==2){
             //if(this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(10); this.reStart = false;  }
             
             //this.reStart=false;
             if(player.currentTime()>10){
                 this.showDeepening = true;
             }else{
                 this.showDeepening = false;
             }
             
         }else{
             this.showDeepening = false;
         }
         
         
      },
      onPlayerCanplay(player) {
         console.log('player Canplay!', player);
         
      },
      onPlayerCanplaythrough(player) {
         console.log('player Canplaythrough!', player);

      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState);
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player);
        
        //player.currentTime(0);
        // console.log('example 01: the player is readied', player)
      },
       myEventHandler(e) {
             // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            this.player.height(this.windowHeight-110); 
            //this.player.height(e.target.innerHeight-40);


            this.windowWidth = e.target.innerWidth;
            this.computedHeight = e.target.innerHeight+'px';
            this.computedHeightImg = (e.target.innerHeight-60)+'px';
            this.computedWidthImg = this.windowWidth+'px';

            if(e.target.innerHeight>e.target.innerWidth){
              this.computedHeightImg = 'auto';
            }else{
              this.computedWidthImg = 'auto';
            }
            
      },
      showLinkFunction(){
          this.showLink = true;
      },
      changeSlidingText(){

        if(this.cosaRiproduco==2){
          this.$refs.a1857.className  = 'terremotiArrowG';
        }

        if(this.cosaRiproduco==1){
          this.$refs.a1857.className  = 'terremotiArrow';
        }
        
        setTimeout(() => this.changeSlidingText(),20000);
        
      },
      playSound (sound) {
        if(sound) {
            this.audioObject = new Audio(sound);
            this.audioObject.play();
        }
      },
      stopSound (sound) {
        if(sound) {
            
            this.audioObject.pause();
            //this.audioObject.currentTime = 0;
        }
      },
      recallSound(sound){
          if(this.audioCanPlay==true){
            if(this.cosaRiproduco==1){
                this.playSound(sound);
            }
            
            setTimeout(() => this.recallSound(sound), 160000);
            console.log('qui?????');
          }
          
      },

    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        this.audioCanPlay = true;
        setTimeout(() => this.recallSound(this.audioFile), 500);
        setTimeout(() => this.showLinkFunction(), 1000);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
        this.audioCanPlay = false;
        this.stopSound(this.audioFile);
    },
  }
</script>
<style scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active { 
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .terremotiArrow{
    left: -10px;
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrow div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .terremotiArrowG{
    left: -10px;
    background-color: green;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrowG div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .notvisible{
    visibility: hidden;
    
    overflow: hidden;
    width: 0;
    height: 0;
      
  }
  .yesvisible{
    visibility: visible;
    margin-top: 60px;
    overflow: hidden;
    width: unset;
    height: unset;
      
  }

  p{
      font-size: 1.4em;
  }

</style>