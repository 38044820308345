<template>

<div class="start sf"> <!-- MAIN CONTAINER -->

  <div v-if="cosaRiproduco==1">
    <img src="img/t06.jpg" :style="{ height: computedHeightImg, 'margin-top': '55px', width: computedWidthImg }" />
  </div>

  <div v-if="cosaRiproduco==4" ref="authors" class="testodoc">
    <p>
        <strong>XVII secolo - Cultura</strong><br><br>
       Crisi produttive, carestie, elevata mortalità, terre devastate dalla guerraò in questo secolo la qualità della vita diminuisce sensibilmente in tutta Italia. Politicamente trionfano gli assolutismi. Le città "invecchiano" nella crisi economica generale si costruisce poco e male; il patrimonio edilizio subisce un degrado diffuso, di cui sono in parte espressione alcuni grandi disastri sismici. <br>
In campo culturale, si osserva una prima affermazione della tecnica e della fisica; la stessa filosofia in quest'epoca è scienza e osservazione. Una nuova curiosità scientifica esprime, nel campo degli studi astronomici, intelletti del calibro di Keplero e di Newton. Galileo con i suoi studi astronomici "rimuove" la terra dal centro del sistema solare; Cartesio con la sua filosofia riporta l'uomo al centro dell'esperienza terrena, attraverso le modalità della conoscenza e del pensiero. Si tratta tuttavia di avanguardie del sapere, che conoscono una diffusione culturale assai limitata, sia a causa delle difficili condizioni sociali sia a causa del contrasto con la Chiesa, che tende a non favorire i processi di razionalizzazione dei fenomeni naturali, tra cui anche il terremoto. <br>
In questo secolo danno notizia dei terremoti non solo le gazzette a stampa - peraltro in crescita continua. L'attenzione generale per il mondo naturale, l'ansia di indagine e di spiegazione, non lasciano inesplorato un campo così vasto e così ricco di ripercussioni pratiche come lo studio dei fenomeni sismici. Si moltiplicano trattati e opere che vengono letti dagli uomini colti d'Europa, facilitati ancora dall'uso scritto di un'unica lingua, il latino.<br>

        <br><br><strong>Scienza</strong><br><br>
        In questo secolo, di grande importanza per tutta la scienza, si afferma l'interpretazione "meccanica" degli eventi naturali, dall'origine del sistema solare alla struttura della Terra. Nonostante questa dirompente novità, che segna una svolta fondamentale per la riflessione sui fenomeni terrestri, le scienze non sono ancora separate dalla filosofia e nemmeno dalla religioneò il Seicento è infatti anche il secolo in cui si cerca di armonizzare la visione religiosa e provvidenzialistica con la filosofia meccanica. Le ipotesi sulle cause dei terremoti sono dunque una fra le grandi questioni scientifico-filosofiche discusseò l'origine e l'età della Terra, il diluvio universale, l'interpretazione dei fossili. Questioni arricchite e stimolate dalle nuove osservazioni geologiche, in particolare nel campo della sedimentologia e della petrografia.<br>
La grande opera di René Descartes (1596-1650), "Principia philosophiae", pubblicata nel 1644 a Parigi, apre la fase "meccanicistica" della concezione della Terra. In questo testo ogni fenomeno, dall'origine del globo alla sua estinzione, è spiegato come effetto del moto degli "elementi" costituenti la materia; allo stesso modello teorico si rifà l'ipotesi sulla genesi dei terremoti. Secondo Descartes, le esalazioni che provengono dall'interno della Terra, combinate in vario modo per formare lo zolfo, il bitume, il petrolio e l'argilla, si aggregano secondo il loro moto più o meno forte e, incuneate nelle fessure e cavità della Terra, compongono un "fumo grasso e denso, paragonabile a quello che esce da una candela quando è stata spenta".  Questo fumo, all'accendersi casuale di una scintilla, diventa fiamma e preme con grande violenza sui lati della cavitàò uno scoppio che genera in superficie il terremoto, la cui violenza è quindi variabile secondo la quantità di esalazioni infiammabili e l'ampiezza delle discontinuità terrestri.  <br>
Sostenitore dell'origine ignea dei terremoti è anche il gesuita tedesco Athanasius Kircker (1601-1680), che nel suo trattato "Mundus subterraneus" (pubblicato ad Amsterdam nel 1665), postula l'esistenza di un grande fuoco al centro della Terra, collegato a focolai minori, attraverso un complesso reticolo di canali sotterranei e gallerie, di cui pubblica una suggestiva rappresentazione. Kircker spiega così l'origine dei terremoti e dei vulcani entro un unico modello unitario, utilizzando le preesistenti teorie delle materie infiammabili.<br>
Più accettata dai filosofi ma più discussa dai naturalisti, la concezione della natura come insieme di materia e di moto governato solo da leggi stenta a prevalere. Molti geologi del Seicento accettano l'ipotesi di Descartes sulla struttura a strati della Terra, ma rifiutano la sua filosofia naturalista e cercano concordanze con la Bibbia. I creazionisti, sostenitori della teoria sacra, non elaborano, d'altronde, un apporto originale alle teorie interpretative.<br>
La teoria degli scoppi di sostanze infiammabili in compresenza di un fuoco centrale terrestre, detta teoria fuochista, risulta comunque prevalente verso la fine del Seicento, anche se posta entro sistemi cosmogonici diversiò è adottata con poche varianti da molti altri fisici e naturalisti, spesso all'interno di una concezione in cui Dio pu˜ comunque essere il motore di ogni fenomeno. <br>
<br>
L'astrologo Flaminio Mezzavacca scrisse nel 1672 un trattato relativo al terremoto del Riminese del 14 aprile dello stesso anno, dedicandolo al "decano dei senatori bolognesi, Geronimo Capacello de Albergati". Nel suo scritto, Mezzavacca pass˜ in rassegna le teorie filosofiche antiche riguardo ai terremoti, e dopo aver accennato in breve a quelle di Pitagora, Empedocle, Anassimene, Anassagora, Democrito, Anassimandro, e altri, fino ad Epicuro, dichiarò: "con Aristotele dico che la causa efficiente dei terremoti è il calore del sole che penetra nelle viscere della terra, la causa materiale sono gli spiriti generatisi nella terra o ivi immessi da qualche altro luogo, la causa formale è lo scontro e scuotimento degli stessi, e la causa finale è la conservazione di quegli stessi spiriti nel luogo naturale". In sostanza, Mezzavacca si dichiarava in accordo con la teoria aristotelica dei venti sotterranei, generati dal calore, il cui agitarsi nelle cavità terrestri avrebbe prodotto i terremoti. Dopo aver esposto le sue ipotesi sui luoghi e i periodi più soggetti ai terremoti, nel capitolo dedicato ai segni premonitori Mezzavacca tracciò i quadri astrologici del cielo al momento di diversi terremoti, fra cui quello del 20 giugno del 1671, fornendo una lunga spiegazione delle "ragioni" astrologiche del fenomeno, attribuito fra l'altro ad una posizione sfavorevole del pianeta Giove.
    </p>
  </div>
  
  <transition name="slide-fade" >
    <span ref="a1694" v-if="showLink" @click="startVideo(2); testoCheScorre='Nasce Voltaire, scrittore e filosofo - Fondata la Banca d\'Inghilterra'" class="terremotiArrow" style="mposition:absolute; top:70px; z-index:20; cursor:pointer; position: absolute; ">
      <div>1694</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="showDeepening" @click="startVideo(3)" class="terremotiArrow" style="mposition:absolute; top:120px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Approfondimento</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="showDeepening" @click="startVideo(5)" class="terremotiArrow" style="mposition:absolute; top:170px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Approfondimento</div>
    </span>
  </transition>

    <video-player :class="videoPlayerBox"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
 
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 >
  </video-player>

   <div id="nav" class="marquee" >
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhereGlobe(); testoCheScorre=''" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario'); testoCheScorre=''" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori'); testoCheScorre=''" src="img/autori.png" class="rightmastericons" />
        </div>

        <div class="documentiicon">
            <img @click="cosaRiproduco=4; goToDocument(); testoCheScorre=''" src="img/documenti-icon.png" class="rightmastericons" />
        </div>

    </div>

  <div id="footer" class="marquee" >
      <p v-html="testoCheScorre">
        
        
      </p>
    </div>

</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Dalla metà del XVI secolo al 1713  ',
        testoCheScorre : '',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
        computedHeightImg : '200px',
        computedWidthImg : '200px',
        audioFile : 'audio/06.mp3',
        videoPlayerBox : 'video-player-box notvisible',
        audioCanPlay : false,
        audioObject : null,
        cosaRiproduco : 1,
        cosaRiproducoPosizione : 0,
        showLink : false,
        showDeepening : false,
        reStart : false,
        playerOptions: {
          // videojs options
          muted: false,
          controls: false,
          autoplay: false,
          language: 'it',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: "video/1694/1694.mp4"
          }],
          //poster: "/public/img/mstile-150x150.png",
        }
        
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)

      this.computedHeight = this.windowHeight+'px';
      this.computedHeightImg = (this.windowHeight-60)+'px';
      this.computedWidthImg = this.windowWidth+'px';

      if(this.windowHeight>this.windowWidth){
        this.computedHeightImg = 'auto';
      }else{
        this.computedWidthImg = 'auto';
      }
      console.log(this.windowHeight);

      this.player.width(this.windowWidth);
      this.player.height(this.windowHeight-110); 
      
    },
    computed: {
     player() {
        return this.$refs.videoPlayer.player
      },
      
    },
    methods: {
      startVideo(what){
          this.cosaRiproduco = what;
          this.videoPlayerBox = 'video-player-box yesvisible';
          console.log(this.videoPlayerBox);
          // il primo video
          if(what==2){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = 0;
              this.player.src("video/1694/1694.mp4");
              this.player.currentTime(120);
              this.player.play();
              console.log(this.player.currentTime());
          }
          if(what==3){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1694/1694_T01/1694_T01.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }
          if(what==5){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1694/1694_T02/1694_T02.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }
          this.changeSlidingText();

      },
      goToDocument(){
        this.stopSound(this.audioFile);
        this.videoPlayerBox = 'video-player-box notvisible';
        this.cosaRiproducoPosizione = this.player.currentTime();
        this.player.pause();
      },
      goWhereGlobe(){
          // resetto e torno indietro #########################################
          console.log(this.cosaRiproduco+'<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
            if(this.cosaRiproduco==1){
                router.push('/home');
            }

            if(this.cosaRiproduco==2){
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
                //this.cosaRiproducoPosizione = 0;
            }

            if(this.cosaRiproduco==3 || this.cosaRiproduco==5){
                this.cosaRiproduco=2;
                this.player.pause();
                this.player.src("video/1694/1694.mp4");
                //this.player.currentTime(this.cosaRiproducoPosizione);
                this.player.play();
                
            }

            if(this.cosaRiproduco==4){ 
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
            }

            console.log(this.cosaRiproduco);
            this.changeSlidingText();
       },
      goWhere(destination){
            //let whereReturn = localStorage.getItem('whereicomefrom');
            localStorage.setItem('whereicomefrom', 't06');
            if(destination=='back'){
                router.push('/home');
            }else{
                router.push('/'+destination);
            }
             
       },
       // listen event
      onPlayerPlay(player) {
         console.log('player play!'+ player + 'ppp' + parseInt(this.cosaRiproducoPosizione));
         if(this.cosaRiproduco==2){player.currentTime(parseInt(this.cosaRiproducoPosizione));}else{ player.currentTime(0); }
         this.videoStatus = 'PLAY';
      },
      onPlayerPause(player) {
         console.log('player pause!', player);
         this.videoStatus = 'PAUSE';
      },
      onPlayerEnded(player) {
         console.log('player ended!', player);
         this.videoStatus = 'ENDED';
         this.goWhereGlobe();
      },
      onPlayerLoadeddata(player) {
         console.log('player Loadeddata!', player);
      },
      onPlayerWaiting(player) {
         console.log('player Waiting!', player);
      },
      onPlayerPlaying(player) {
         console.log('player Playing!', player);
         
      },
      onPlayerTimeupdate(player) {
          //if(player.currentTime()<30){player.currentTime(30)}
          //if(this.cosaRiproduco==2 && this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(30);  console.log('dio');  }

         console.log('player Timeupdate!'+ player.currentTime() + '---' + parseInt(this.cosaRiproducoPosizione) + '|||' + this.reStart);
         if(this.cosaRiproduco==2){
             //if(this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(10); this.reStart = false;  }
             
             //this.reStart=false;
             if(player.currentTime()>10){
                 this.showDeepening = true;
             }else{
                 this.showDeepening = false;
             }
             
         }else{
             this.showDeepening = false;
         }
         
         
      },
      onPlayerCanplay(player) {
         console.log('player Canplay!', player);
         
      },
      onPlayerCanplaythrough(player) {
         console.log('player Canplaythrough!', player);

      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState);
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player);
        
        //player.currentTime(0);
        // console.log('example 01: the player is readied', player)
      },
       myEventHandler(e) {
             // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            this.player.height(this.windowHeight-110); 
            //this.player.height(e.target.innerHeight-40);


            this.windowWidth = e.target.innerWidth;
            this.computedHeight = e.target.innerHeight+'px';
            this.computedHeightImg = (e.target.innerHeight-60)+'px';
            this.computedWidthImg = this.windowWidth+'px';

            if(e.target.innerHeight>e.target.innerWidth){
              this.computedHeightImg = 'auto';
            }else{
              this.computedWidthImg = 'auto';
            }
            
      },
      showLinkFunction(){
          this.showLink = true;
      },
      changeSlidingText(){

        if(this.cosaRiproduco==2){
          this.$refs.a1694.className  = 'terremotiArrowG';
        }

        if(this.cosaRiproduco==1){
          this.$refs.a1694.className  = 'terremotiArrow';
        }
        
        setTimeout(() => this.changeSlidingText(),20000);
        
      },
      playSound (sound) {
        if(sound) {
            this.audioObject = new Audio(sound);
            this.audioObject.play();
        }
      },
      stopSound (sound) {
        if(sound) {
            
            this.audioObject.pause();
            //this.audioObject.currentTime = 0;
        }
      },
      recallSound(sound){
          if(this.audioCanPlay==true){
            if(this.cosaRiproduco==1){
                this.playSound(sound);
            }
            
            setTimeout(() => this.recallSound(sound), 160000);
            console.log('qui?????');
          }
          
      },

    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        this.audioCanPlay = true;
        setTimeout(() => this.recallSound(this.audioFile), 500);
        setTimeout(() => this.showLinkFunction(), 1000);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
        this.audioCanPlay = false;
        this.stopSound(this.audioFile);
    },
  }
</script>
<style scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active { 
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .terremotiArrow{
    left: -10px;
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrow div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .terremotiArrowG{
    left: -10px;
    background-color: green;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrowG div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .notvisible{
    visibility: hidden;
    
    overflow: hidden;
    width: 0;
    height: 0;
      
  }
  .yesvisible{
    visibility: visible;
    margin-top: 60px;
    overflow: hidden;
    width: unset;
    height: unset;
      
  }

  p{
      font-size: 1.4em;
  }
</style>