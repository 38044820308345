<template>

<div class="start sf"> <!-- MAIN CONTAINER -->

  <div v-if="cosaRiproduco==1">
    <img src="img/t07.jpg" :style="{ height: computedHeightImg, 'margin-top': '55px', width: computedWidthImg }" />
  </div>

  <div v-if="cosaRiproduco==4" ref="authors" class="testodoc">
    <p>
        <strong>XVIII secolo - Cultura</strong><br><br>
        Individualista e razionale, questo secolo inneggia allo sgretolamento degli idoli, cerca la traccia di meccanismi naturali e regolarità comprensibili: è il secolo dei lumi. Dopo le difficili vicende di successione dinastica dei primi decenni, le monarchie europee si orientano verso una linea politica di moderato riformismo: per qualche tempo l'Europa conosce una pace che si rivela favorevole allo sviluppo delle nuove correnti di pensiero.<br>
Nel mondo delle scienze e delle lettere, il nuovo orientamento culturale tende a considerare la persona umana come misura degli eventi e delle relazioni, secondo uno schema di pensiero soggettivista; si pongono le basi di una razionalità che coinvolge tutti i campi, da quello artistico a quello economico. <br>
La stampa, oltre a fornire un mezzo prezioso alla diffusione delle idee e dei fatti in un mondo sempre più conosciuto, testimonia l'esigenza, profondamente sentita, di libertà di pensiero.<br>
In quest'epoca si organizzano, e si articolano in grandi teorie, i risultati delle esperienze di studio e di ricerca del secolo precedente. Per quanto riguarda i terremoti, la coscienza culturale europea è colpita da due grandi disastri: quello del 1755 di Lisbona e quello del 1783 della Calabria, che divengono occasione di vastissime riflessioni non solo scientifiche ma anche etiche e filosofiche. <br>
Filosofi naturali ed eruditi settecenteschi inscrivono così a pieno titolo il terremoto nel quadro della cultura del secolo: non più fatto tra i fatti, ma fenomeno integrato nella rete illuminata di spiegazioni che percorre il mondo.<br>
        <br><br><br><strong>Scienza</strong><br><br>
        In questo secolo si pone termine, non senza qualche ambiguità, al compromesso fra Genesi e scienze naturali. La riflessione sulle cause dei terremoti è stimolata anche da una sequenza ravvicinata di importanti eventi sismici: il terremoto peruviano di Callao e Lima del 1746, poi i due eventi sismici dell'Inghilterra del 1749 e del 1750, che sconcertano gli Inglesi, fino ad allora convinti dell'asismicità pressoché totale della loro isola. Nel 1751 un terremoto dell'Italia centrale (Perugia) offre occasione per nuove osservazioni. <br>
Alcuni anni dopo, nel 1755, una immane catastrofe sismica distrugge Lisbona e un trentennio dopo, nel 1783, la Calabria viene ridotta a un cumulo di macerie da ben cinque disastrosi terremoti, che si succedono nel giro poche settimane. Nell'arco di qualche decina d'anni, il pensiero sismologico è, per così dire, "messo alle strette" e sottoposto a una straordinaria accelerazione teorica, sia scientifica sia filosofica.<br>
Tutto il Settecento è caratterizzato dalla convivenza di interpretazioni, esperimenti e teorie diversi sull'origine dei terremoti, che si sostanziano in due teorie principali: la teoria fuochista e la teoria elettricista. 

Direttamente derivata dalle ipotesi di Descartes del secolo precedente, la teoria fuochista è ripresa da Georges Louis Leclerc Buffon (1707-1788), nella sua prima opera, "Histoire et theorie de la Terre", pubblicata a Parigi nel 1749. Buffon sostiene l'ipotesi che i terremoti siano causati dallo scoppio di materie infiammabili, dovuto alla presenza di un fuoco terrestre centrale. 
Sostennero l'interpretazione fuochista del terremoto alcuni scienziati e sperimentatori come Vulturno Tirenio, Giovanni Battista Beccaria e Gian Lodovico Bianconi.
Tirenio (1779), ritenne causa primaria dei terremoti "il fuoco elementare", da solo in grado di produrre tutti gli effetti attribuiti all'elettricità. A suo parere, nelle profondità della terra piena di caverne, cavità, canali, si addensavano vapori infiammabili, prodotti dalla mescolanza e dalla fermentazione di materie bituminose, sulfuree, nitrose, metalliche. Quando essi venivano a contatto con l'aria producevano fuoco e quindi il terremoto, inteso come uno scoppio. Inoltre, fra le diverse cavità della terra vi era comunicazione, e ciò spiegava perché i terremoti avvenivano a volte in luoghi lontanissimi l'uno dall'altro. Secondo questa teoria, il fuoco sotterraneo avrebbe potuto distruggere tutta la terra, se non vi fossero stati i vulcani attraverso cui fuoco e vapori fuoriuscivano. <br>
<br>
La teoria elettricista, invece, prende avvio dalla seconda metà del Settecento, innescata dallo sperimentalismo attorno all'elettricità e al magnetismo terrestre. Secondo questa teoria all'origine del terremoto vi sono fenomeni elettrici, scariche prodotte dalla liberazione di un "fluido elettrico" presente nelle viscere della terra; questo fluido, eccitato dalla presenza di materiale combustibile analogamente contenuto nel sottosuolo, o semplicemente nella ricerca di un proprio stato di equilibrio, causa gli scuotimenti della crosta terrestre.<br>
Il più strenuo, quasi fanatico, sostenitore dell'origine elettrica del terremoto, Pierre Bertholon (1741-1800), prete dell'oratorio di Saint Lazare di Parigi, progetta nel 1779 i "paraterremoti" come mezzo di difesa dalle scosse. Si tratta di lunghe spranghe di ferro che, sprofondate nei terreni soggetti a terremoti, dovrebbero assorbire il "fuoco elettrico" dal sottosuolo e disperderlo nell'atmosfera: una specie di parafulmine inverso che sarà poi progettato e disegnato dall'architetto pontificio Giuseppe Valadier - allora giovanissimo e non ancora famoso, per proteggere Rimini da futuri terremoti.<br>
Sostenitori dell'interpretazione elettricista del terremoto furono gli scienziati Michele Augusti e Sebastiano Canterzani. Augusti (1780) sulla base di esperimenti elettrici condotti da scienziati dell'epoca, sostenne che la causa del terremoto era un accumulo di "vapore elettrico" e che scavando un cratere nel terreno sulfureo, l'elettricità accumulata avrebbe potuto trovare una via d'uscita. Per corroborare questa tesi, portò l'esempio dell'Etna e del Vesuvio, che, con la loro continua attività, proteggevano le città e i territori circostanti da forti eventi sismici.<br>
Anche Canterzani (1834) ritenne che i terremoti derivassero da un accumulo di materie elettriche che si scaricavano nell'atmosfera e Vogli (1791) ipotizzò che vi fosse uno stretto collegamento tra terremoti e livelli di elettricità presenti nell'atmosfera.<br>
<br>
Oltre ai fuochisti e agli elettricisti (con tutte le loro varianti) si sviluppò una terza teoria, basata sull'ipotesi che l'aria compressa, soprattutto calda, possa essere la causa del terremoto. Quest'idea dell'urto è in qualche modo connessa ai coevi esperimenti sui fucili ad aria compressa. <br>
Nel 1760 John Mitchell (1724-1793), professore nel Collegio della Regina a Cambridge, pubblica nelle "Philosophical Transactions" (vol. 51: pp.566-634) le sue Conjectures, evidenziando l'analogia esistente fra i fenomeni sismici e vulcanici, inducendone che la loro origine sia comune. La sua teoria diviene poi nota con il termine "vulcanesimo". Mitchell studia minutamente la direzione, la forza, l'ampiezza del moto del suolo, e il tempo di propagazione, applicando per la prima volta la teoria delle onde. La modernità del suo approccio è indiscussa, ma i contemporanei, spesso ancora legati a un pensiero metafisico e deduttivo, faticano a comprenderlo.<br>
Da un punto di vista epistemologico, in questo secolo i procedimenti logici sono ancora per lo più deduttivi, basati su assiomi e procedimenti analogici. Rispetto alle coeve discipline scientifiche, la chimica soprattutto, ma anche la geologia degli ultimi decenni del Settecento, il pensiero sismologico appare ancora stretto in questioni metafisiche, e sembra subire l'ultimo fascino delle prospettive cosmogoniche, nell'impossibilità però di sviluppare un suo lato "sperimentale". Riguardo all'interpretazione del terremoto, il Settecento si chiude con un pensiero naturalistico complessivamente consolidato, slegato da istanze religiose.<br>
    </p>
  </div>
  
  <transition name="slide-fade" >
    <span ref="a1783" v-if="showLink" @click="startVideo(2); testoCheScorre='I fratelli Joseph-Michel e Jacques-Etienne Mongolfier inventano il primo aerostato ad aria calda che in loro onore sarà chiamato Mongolfiera &nbsp;&nbsp;&nbsp; 24 luglio 1783. Nasce a Caracas Simòn Bolivar, diventerà il simbolo della liberazione del Sud America dal dominio spagnolo &nbsp;&nbsp;&nbsp; Il 3 settembre la Gran Bretagna riconosce l\'indipendenza delle 13 colonie americane. Nascono così gli Stati Uniti d\'America'" class="terremotiArrow" style="mposition:absolute; top:70px; z-index:20; cursor:pointer; position: absolute; ">
      <div>1783</div>
    </span>
  </transition>

  <transition name="slide-fade" >
    <span v-if="showDeepening" @click="startVideo(3)" class="terremotiArrow" style="mposition:absolute; top:120px; z-index:20; cursor:pointer; position: absolute; ">
      <div>Approfondimento</div>
    </span>
  </transition>

    <video-player :class="videoPlayerBox"
                 ref="videoPlayer"
                 :options="playerOptions"
                 :playsinline="true"
                 customEventName="customstatechangedeventname"
 
                 @play="onPlayerPlay($event)"
                 @pause="onPlayerPause($event)"
                 @ended="onPlayerEnded($event)"
                 @waiting="onPlayerWaiting($event)"
                 @playing="onPlayerPlaying($event)"
                 @loadeddata="onPlayerLoadeddata($event)"
                 @timeupdate="onPlayerTimeupdate($event)"
                 @canplay="onPlayerCanplay($event)"
                 @canplaythrough="onPlayerCanplaythrough($event)"
 
                 @statechanged="playerStateChanged($event)"
                 @ready="playerReadied"
                 >
  </video-player>

   <div id="nav" class="marquee" >
        <div style="font-size: 1.4em; line-height: 22px; color: black; font-weight: bold;">{{this.title}}</div>
       <!--
        <router-link to="/">Home</router-link> |
        <router-link to="/about">About</router-link>
        -->
        <div class="wordlicon">
            <img @click="goWhereGlobe(); testoCheScorre=''" src="img/Earth-spinning-rotating-animation-40.gif" class="rightmastericons" />
        </div>

        <div class="glossarioicon">
            <img @click="goWhere('glossario'); testoCheScorre=''" src="img/glossary-icon2.png" class="rightmastericons" />
        </div>

        <div class="autoriicon">
            <img @click="goWhere('autori'); testoCheScorre=''" src="img/autori.png" class="rightmastericons" />
        </div>

        <div class="documentiicon">
            <img @click="cosaRiproduco=4; goToDocument(); testoCheScorre=''" src="img/documenti-icon.png" class="rightmastericons" />
        </div>

    </div>

  <div id="footer" class="marquee" >
      <p v-html="testoCheScorre">
        
        
      </p>
    </div>

</div>
  
</template>

 
<script>

import router from '@/router';

  // Similarly, you can also introduce the plugin resource pack you want to use within the component
  // import 'some-videojs-plugin'
  export default {
    data() {
      return {
        title : 'Dal 1714 al 1814 ',
        testoCheScorre : '',
        videoStatus : 'START',
        aspectRatio : '16:9',
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
        computedHeight : '500px',
        computedHeightImg : '200px',
        computedWidthImg : '200px',
        audioFile : 'audio/07.mp3',
        videoPlayerBox : 'video-player-box notvisible',
        audioCanPlay : false,
        audioObject : null,
        cosaRiproduco : 1,
        cosaRiproducoPosizione : 0,
        showLink : false,
        showDeepening : false,
        reStart : false,
        playerOptions: {
          // videojs options
          muted: false,
          controls: false,
          autoplay: false,
          language: 'it',
          playbackRates: [0.7, 1.0, 1.5, 2.0],
          sources: [{
            type: "video/mp4",
            src: "video/1783/1783.mp4"
          }],
          //poster: "/public/img/mstile-150x150.png",
        }
        
      }
    },
    mounted() {
      console.log('this is current player instance object', this.player)

      this.computedHeight = this.windowHeight+'px';
      this.computedHeightImg = (this.windowHeight-60)+'px';
      this.computedWidthImg = this.windowWidth+'px';

      if(this.windowHeight>this.windowWidth){
        this.computedHeightImg = 'auto';
      }else{
        this.computedWidthImg = 'auto';
      }
      console.log(this.windowHeight);

      this.player.width(this.windowWidth);
      this.player.height(this.windowHeight-110); 
      
    },
    computed: {
     player() {
        return this.$refs.videoPlayer.player
      },
      
    },
    methods: {
      startVideo(what){
          this.cosaRiproduco = what;
          this.videoPlayerBox = 'video-player-box yesvisible';
          console.log(this.videoPlayerBox);
          // il primo video
          if(what==2){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = 0;
              this.player.src("video/1783/1783.mp4");
              this.player.currentTime(120);
              this.player.play();
              console.log(this.player.currentTime());
          }
          if(what==3){
              this.stopSound(this.audioFile);
              this.cosaRiproducoPosizione = this.player.currentTime();
              this.reStart = true;
              this.player.pause();
              this.player.src("video/1783/1783_T01/1783_T01.mp4");
              //this.player.currentTime(20);
              this.player.play();
          }
          this.changeSlidingText();

      },
      goToDocument(){
        this.stopSound(this.audioFile);
        this.videoPlayerBox = 'video-player-box notvisible';
        this.cosaRiproducoPosizione = this.player.currentTime();
        this.player.pause();
      },
      goWhereGlobe(){
          // resetto e torno indietro #########################################
          console.log(this.cosaRiproduco+'<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<');
            if(this.cosaRiproduco==1){
                router.push('/home');
            }

            if(this.cosaRiproduco==2){
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
                //this.cosaRiproducoPosizione = 0;
            }

            if(this.cosaRiproduco==3){
                this.cosaRiproduco=2;
                this.player.pause();
                this.player.src("video/1783/1783.mp4");
                //this.player.currentTime(this.cosaRiproducoPosizione);
                this.player.play();
                
            }

            if(this.cosaRiproduco==4){ 
                this.cosaRiproduco=1;
                this.videoPlayerBox = 'video-player-box notvisible';
                this.player.pause();
            }

            console.log(this.cosaRiproduco);
            this.changeSlidingText();
       },
      goWhere(destination){
            //let whereReturn = localStorage.getItem('whereicomefrom');
            localStorage.setItem('whereicomefrom', 't07');
            if(destination=='back'){
                router.push('/home');
            }else{
                router.push('/'+destination);
            }
             
       },
       // listen event
      onPlayerPlay(player) {
         console.log('player play!'+ player + 'ppp' + parseInt(this.cosaRiproducoPosizione));
         if(this.cosaRiproduco==2){player.currentTime(parseInt(this.cosaRiproducoPosizione));}else{ player.currentTime(0); }
         this.videoStatus = 'PLAY';
      },
      onPlayerPause(player) {
         console.log('player pause!', player);
         this.videoStatus = 'PAUSE';
      },
      onPlayerEnded(player) {
         console.log('player ended!', player);
         this.videoStatus = 'ENDED';
         this.goWhereGlobe();
      },
      onPlayerLoadeddata(player) {
         console.log('player Loadeddata!', player);
      },
      onPlayerWaiting(player) {
         console.log('player Waiting!', player);
      },
      onPlayerPlaying(player) {
         console.log('player Playing!', player);
         
      },
      onPlayerTimeupdate(player) {
          //if(player.currentTime()<30){player.currentTime(30)}
          //if(this.cosaRiproduco==2 && this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(30);  console.log('dio');  }

         console.log('player Timeupdate!'+ player.currentTime() + '---' + parseInt(this.cosaRiproducoPosizione) + '|||' + this.reStart);
         if(this.cosaRiproduco==2){
             //if(this.cosaRiproducoPosizione!=0 && this.reStart==true){ player.currentTime(10); this.reStart = false;  }
             
             //this.reStart=false;
             if(player.currentTime()>10){
                 this.showDeepening = true;
             }else{
                 this.showDeepening = false;
             }
             
         }else{
             this.showDeepening = false;
         }
         
         
      },
      onPlayerCanplay(player) {
         console.log('player Canplay!', player);
         
      },
      onPlayerCanplaythrough(player) {
         console.log('player Canplaythrough!', player);

      },
      // or listen state event
      playerStateChanged(playerCurrentState) {
         console.log('player current update state', playerCurrentState);
      },
      // player is ready
      playerReadied(player) {
        // seek to 10s
        console.log('example player 1 readied', player);
        
        //player.currentTime(0);
        // console.log('example 01: the player is readied', player)
      },
       myEventHandler(e) {
             // your code for handling resize...
            //console.log(e);
            console.log(e.target.innerWidth);
            console.log(e.target.innerHeight);
            this.player.height(this.windowHeight-110); 
            //this.player.height(e.target.innerHeight-40);


            this.windowWidth = e.target.innerWidth;
            this.computedHeight = e.target.innerHeight+'px';
            this.computedHeightImg = (e.target.innerHeight-60)+'px';
            this.computedWidthImg = this.windowWidth+'px';

            if(e.target.innerHeight>e.target.innerWidth){
              this.computedHeightImg = 'auto';
            }else{
              this.computedWidthImg = 'auto';
            }
            
      },
      showLinkFunction(){
          this.showLink = true;
      },
      changeSlidingText(){

        if(this.cosaRiproduco==2){
          this.$refs.a1783.className  = 'terremotiArrowG';
        }

        if(this.cosaRiproduco==1){
          this.$refs.a1783.className  = 'terremotiArrow';
        }
        
        setTimeout(() => this.changeSlidingText(),20000);
        
      },
      playSound (sound) {
        if(sound) {
            this.audioObject = new Audio(sound);
            this.audioObject.play();
        }
      },
      stopSound (sound) {
        if(sound) {
            
            this.audioObject.pause();
            //this.audioObject.currentTime = 0;
        }
      },
      recallSound(sound){
          if(this.audioCanPlay==true){
            if(this.cosaRiproduco==1){
                this.playSound(sound);
            }
            
            setTimeout(() => this.recallSound(sound), 160000);
            console.log('qui?????');
          }
          
      },

    },
    created() {
        window.addEventListener("resize", this.myEventHandler);
        this.audioCanPlay = true;
        setTimeout(() => this.recallSound(this.audioFile), 500);
        setTimeout(() => this.showLinkFunction(), 1000);
    },
    destroyed() {
        window.removeEventListener("resize", this.myEventHandler);
        this.audioCanPlay = false;
        this.stopSound(this.audioFile);
    },
  }
</script>
<style scoped>
  /* Enter and leave animations can use different */
  /* durations and timing functions.              */
  .slide-fade-enter-active { 
    transition: all .3s ease;
  }
  .slide-fade-leave-active {
    transition: all .8s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateX(10px);
    opacity: 0;
  }

  .terremotiArrow{
    left: -10px;
    background-color: red;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrow div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .terremotiArrowG{
    left: -10px;
    background-color: green;
    color: white;
    font-weight: bold;
    padding: 8px;
    padding-right: 30px;
    font-size: 1.2em;
    transform: skewX(20deg); 
    -ms-transform: skewX(20deg);
    -webkit-transform: skewX(20deg);
  }

  .terremotiArrowG div{
    transform: skewX(-20deg); 
    -ms-transform: skewX(-20deg);
    -webkit-transform: skewX(-20deg);
    padding-left: 20px;
    font-size: 0.8em;
  }

  .notvisible{
    visibility: hidden;
    
    overflow: hidden;
    width: 0;
    height: 0;
      
  }
  .yesvisible{
    visibility: visible;
    margin-top: 60px;
    overflow: hidden;
    width: unset;
    height: unset;
      
  }

  p{
      font-size: 1.4em;
  }
</style>